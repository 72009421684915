import React, { useState, useEffect, forwardRef } from "react";
import {
    Container, Slide, Box, Divider, Typography, Grid, Paper,
    Card, CardActionArea, CardMedia, CardContent, CardHeader, Avatar,
    Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@mui/material/colors';
import { styled } from "@mui/material/styles";
import "../index.css";
import Breadcrumb from "../components/layout/Breadcrumb";
import OrganizationChart from "@dabeng/react-orgchart";

// React Router
import { useNavigate } from "react-router-dom";

const breadcrumbData = ["主頁", "關於我們", "組織架構"];

function OrganiztionPage(props) {
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [data] = useState(

        {
            id: "1",
            name: "Lao Lao",
            title: "general manager",
            children: [
                { id: "2", name: "Bo Miao", title: "department manager" },
                {
                    id: "3",
                    name: "Su Miao",
                    title: "department manager",
                    children: [
                        { id: "4", name: "Tie Hua", title: "senior engineer" },
                        {
                            id: "5",
                            name: "Hei Hei",
                            title: "senior engineer",
                            children: [
                                { id: "6", name: "Dan Dan", title: "engineer" },
                                { id: "7", name: "Xiang Xiang", title: "engineer" }
                            ]
                        },
                        { id: "8", name: "Pang Pang", title: "senior engineer" }
                    ]
                },
                { id: "9", name: "Hong Miao", title: "department manager" },
                {
                    id: "10",
                    name: "Chun Miao",
                    title: "department manager",
                    children: [{ id: "11", name: "Yue Yue", title: "senior engineer" }]
                }
            ]
        }

    )

    // change title
    useEffect(() => {
        document.title = '組織架構';
    });

    const nodeTemplate = ({ nodeData }) => {
        return (
            <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                    <CardMedia>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            backgroundColor={grey[200]}
                            sx={{ px: 2, py: 1 }}>
                            <Avatar
                                src="/img/temp-image.jpeg"
                                sx={{ width: 100, height: 100 }} />
                        </Box>
                    </CardMedia>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {nodeData.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {nodeData.title}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    };

    return (
        <RootBox sx={{ minHeight: 'calc(100vh - 210px)' }}>
            <Root className={classes.root}>
                <Box sx={{ mt: { xs: 8.25, md: 12.5 } }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
                    <Box sx={{ my: 4 }}>
                        <Typography variant="h4">{"組織架構"}</Typography>
                    </Box>
                    <Box>
                        <div>
                            {/* <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "2rem" }}>
                                            XXX
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            XXX
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                    >
                                        <Box
                                            component="img"
                                            sx={{
                                                maxHeight: { xs: 233 },
                                                maxWidth: { xs: '100vw' },
                                            }}
                                            alt="/img/temp-image.jpeg"
                                            src="/img/temp-image.jpeg"
                                        />
                                    </Box>
                                    <Box
                                        sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                    >
                                        <Typography>
                                            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
                                            Aliquam eget maximus est, id dignissim quam.
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                    >
                                        <Box
                                            component="img"
                                            sx={{
                                                maxHeight: { xs: 233 },
                                                maxWidth: { xs: '100vw' },
                                            }}
                                            alt="/img/temp-image.jpeg"
                                            src="/img/temp-image.jpeg"
                                        />
                                        <Box>
                                            <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
                                                Aliquam eget maximus est, id dignissim quam.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion> */}
                            {/* To Be Removed */}
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            會長
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            陳耀恒
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-1.png"
                                                src="/img/organization/organization-1.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                                陳耀恒先生自2006年起加入建造業從事金屬工程，在建築項目管理方面擁有豐富經驗。他修讀了數個學位課程，包括：工商管理、建築項目管理和土木工程學士學位，並持有工商管理及建築項目管理碩士學位。
                                                <br/><br/>
                                                陳先生目前於東淦工程有限公司及坤成建築有限公司擔任董事經理一職。他曾領導多個大型工程，帶領團隊在時間緊迫和高度複雜的建築工程中準時及順利完成項目，具有深厚的建築知識及經驗。
                                                <br/><br/>
                                                於不同組織中，陳先生亦擔當著重要角色。2021年起，他成為香港註冊承建商商會第四屆委員會副會長；於2022年加入建造訓練委員會金屬工藝科課程顧問組；及同年，成為香港鋼結構學會資深會員；2023年加入建造業議會建造業創新及科技應用中心成為管理委員會成員，及獲邀成為香港理工大學的客席教授。
                                                <br/><br/>
                                                陳先生目前是屋宇處認可建築總承包商、地盤平整工程類別專業承包商和地基工程類別專業承包商的技術董事及鋼結構作業守則技術委員會委員。在不同的角色及崗位中，致力推動業界邁向創新及專業發展。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-1.png"
                                                src="/img/organization/organization-1.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    陳耀恒先生自2006年起加入建造業從事金屬工程，在建築項目管理方面擁有豐富經驗。他修讀了數個學位課程，包括：工商管理、建築項目管理和土木工程學士學位，並持有工商管理及建築項目管理碩士學位。
                                                </Typography>
                                                <br />
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    陳先生目前於東淦工程有限公司及坤成建築有限公司擔任董事經理一職。他曾領導多個大型工程，帶領團隊在時間緊迫和高度複雜的建築工程中準時及順利完成項目，具有深厚的建築知識及經驗。
                                                </Typography>
                                                <br />
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    於不同組織中，陳先生亦擔當著重要角色。2021年起，他成為香港註冊承建商商會第四屆委員會副會長；於2022年加入建造訓練委員會金屬工藝科課程顧問組；及同年，成為香港鋼結構學會資深會員；2023年加入建造業議會建造業創新及科技應用中心成為管理委員會成員，及獲邀成為香港理工大學的客席教授。
                                                </Typography>
                                                <br />
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                    陳先生目前是屋宇處認可建築總承包商、地盤平整工程類別專業承包商和地基工程類別專業承包商的技術董事及鋼結構作業守則技術委員會委員。在不同的角色及崗位中，致力推動業界邁向創新及專業發展。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            副會長
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            黃逸郎
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-2.png"
                                                src="/img/organization/organization-2.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                                黃逸郎先生畢業於澳洲科廷大學，持有建築及經濟項目管理學士及碩士學位；同時是香港認可調解員，並獲西澳洲註冊建築承建商資格，熟悉香港及澳洲建築物條例及審批流程。
                                                <br/><br/>
                                                黃先生具有多年豐富金屬工程設計及建造經驗，主要從事户外媒體結構設計及建構工程，曾參與多項大型户外媒體廣告結構設計，項目包括：香港國際機場、港鐵機場快線、電車、巴士站及多個大型地標建築物外牆的廣告工程。他曾參與的國際工程項目也遍達中國，澳洲，星加坡，杜拜，非洲尼日利亞及澳門等地。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-2.png"
                                                src="/img/organization/organization-2.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                黃逸郎先生畢業於澳洲科廷大學，持有建築及經濟項目管理學士及碩士學位；同時是香港認可調解員，並獲西澳洲註冊建築承建商資格，熟悉香港及澳洲建築物條例及審批流程。
                                                </Typography>
                                                <br />
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                黃先生具有多年豐富金屬工程設計及建造經驗，主要從事户外媒體結構設計及建構工程，曾參與多項大型户外媒體廣告結構設計，項目包括：香港國際機場、港鐵機場快線、電車、巴士站及多個大型地標建築物外牆的廣告工程。他曾參與的國際工程項目也遍達中國，澳洲，星加坡，杜拜，非洲尼日利亞及澳門等地。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            秘書長
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            蕭貝丰
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-3.png"
                                                src="/img/organization/organization-3.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                            蕭貝丰先生現為豐業工程有限公司董事，負責管理及執行各類型項目，擁有超過10年的豐富經驗。在香港理工大學畢業後，他修讀了香港科技大學工程企業管理，取得理學碩士學位，並獲邀成為香港建造學院技術專才培訓僱主，致力推動業界邁向專業，培訓年青新一代。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-3.png"
                                                src="/img/organization/organization-3.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                蕭貝丰先生現為豐業工程有限公司董事，負責管理及執行各類型項目，擁有超過10年的豐富經驗。在香港理工大學畢業後，他修讀了香港科技大學工程企業管理，取得理學碩士學位，並獲邀成為香港建造學院技術專才培訓僱主，致力推動業界邁向專業，培訓年青新一代。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            司庫
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            姜銘基
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-4.png"
                                                src="/img/organization/organization-4.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                            姜銘基先生從事建造業超過10年，擁有豐富的經驗。 現時工作於竣煌有限公司，主要負責項目成本管理方面。 公司主要從事鐵器工程、機電工程、冷氣VRV系統、室內設計及裝修。 為推動環保，業務更涉及太陽能新能源等。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-4.png"
                                                src="/img/organization/organization-4.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                姜銘基先生從事建造業超過10年，擁有豐富的經驗。 現時工作於竣煌有限公司，主要負責項目成本管理方面。 公司主要從事鐵器工程、機電工程、冷氣VRV系統、室內設計及裝修。 為推動環保，業務更涉及太陽能新能源等。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            委員
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            陳國彬
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-6.png"
                                                src="/img/organization/organization-6.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                            陳國彬先生於1992年至今一直從事建造業行業工作。具有多年鐵器、鋁質、焊接、玻璃幕牆安裝工程施工及管理經驗。
                                            <br/><br/>
                                            陳先生曾多次參與香港大型建築項目的施工及管理工作。包括香港環球貿易廣場、香港國際機場、香港啓德車站廣場、深水灣徑8號住宅項目等。陳先生現任職安成工程有限公司董事職務，並受委任建造業訓練委員會金屬工藝科課程顧問組（課顧組）成員。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-6.png"
                                                src="/img/organization/organization-6.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                陳國彬先生於1992年至今一直從事建造業行業工作。具有多年鐵器、鋁質、焊接、玻璃幕牆安裝工程施工及管理經驗。
                                                </Typography>
                                                <br/><br/>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                陳先生曾多次參與香港大型建築項目的施工及管理工作。包括香港環球貿易廣場、香港國際機場、香港啓德車站廣場、深水灣徑8號住宅項目等。陳先生現任職安成工程有限公司董事職務，並受委任建造業訓練委員會金屬工藝科課程顧問組（課顧組）成員。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            委員
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            周海雄
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-5.png"
                                                src="/img/organization/organization-5.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                            周海雄先生於2000年創辦新麗工程(亞洲)有限公司，主要從事外牆設計、玻璃、金屬及鋼結構工程項目，曾参與多個工程，如：港鐵寶琳站、尖沙咀東站、香港迪士尼、港島西延綫站、M+博物館、香港機埸擴建工程、西沙行人橋及私人發展商之工程。
                                            <br/><br/>
                                            作為公司創辦人，由基層開始工作，曾兼任物業大厦司庫及管理，亦在2019年亞州知識管理學院獲取亞洲華人領袖獎。在營運過程當中，深信個人力量是微弱的，團隊的力量是最強大。員工作為公司寶貴的資產，公司重視推動發展，致力提升工作效率，與國內工廠和員工並肩合作，制訂公司品牌，凝聚團隊力量。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-5.png"
                                                src="/img/organization/organization-5.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                周海雄先生於2000年創辦新麗工程(亞洲)有限公司，主要從事外牆設計、玻璃、金屬及鋼結構工程項目，曾参與多個工程，如：港鐵寶琳站、尖沙咀東站、香港迪士尼、港島西延綫站、M+博物館、香港機埸擴建工程、西沙行人橋及私人發展商之工程。
                                                </Typography>
                                                <br/><br/>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                作為公司創辦人，由基層開始工作，曾兼任物業大厦司庫及管理，亦在2019年亞州知識管理學院獲取亞洲華人領袖獎。在營運過程當中，深信個人力量是微弱的，團隊的力量是最強大。員工作為公司寶貴的資產，公司重視推動發展，致力提升工作效率，與國內工廠和員工並肩合作，制訂公司品牌，凝聚團隊力量。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            委員
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            陸偉林
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-7.png"
                                                src="/img/organization/organization-7.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                            陸偉林先生，利生建築工程有限公司董事。從事建築金屬行業20年，成立公司以來着重誠信，安全施工，培養人才為宗旨！曾參與設計及安裝多項金屬結構建造工程，包括柯士甸道行人天橋；尖沙咀Apple廣東道店改建樓層結構；荃灣西樓角公園行人天橋等等。陸先生具有多年豐富金屬結構建造經驗，並深得同行多間公司認可及信任！
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-7.png"
                                                src="/img/organization/organization-7.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                陸偉林先生，利生建築工程有限公司董事。從事建築金屬行業20年，成立公司以來着重誠信，安全施工，培養人才為宗旨！曾參與設計及安裝多項金屬結構建造工程，包括柯士甸道行人天橋；尖沙咀Apple廣東道店改建樓層結構；荃灣西樓角公園行人天橋等等。陸先生具有多年豐富金屬結構建造經驗，並深得同行多間公司認可及信任！
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: "1.5rem" }}>
                                            委員
                                        </Typography>
                                        <Typography sx={{ fontSize: "1.25rem" }}>
                                            黃家奎
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDetails>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-8.png"
                                                src="/img/organization/organization-8.png"
                                            />
                                        </Box>
                                        <Box
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'flex', sm: 'none', md: 'none' } }}
                                        >
                                            <Typography sx={{ fontSize: "0.75rem" }}>
                                            黃家奎先生自1992年起加入建造業，擁有三十多年工程經驗。主力從事政府區域性工程為主，如馬路、行人路不同鐵器圍欄、各大小公共空間，如避雨亭或球埸設備、鋼結構臨時行人通道或車輛通道和永久特色標誌工程項目等。
                                            <br/><br/>
                                            除了金屬工程業務外，黃先生亦有接觸標書計算、工程安全管理及施工協助等。
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ mb: 2, flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex' } }}
                                        >
                                            <Box
                                                component="img"
                                                sx={{
                                                    maxHeight: { xs: 233 },
                                                    maxWidth: { xs: '100vw' },
                                                }}
                                                alt="/img/organization/organization-8.png"
                                                src="/img/organization/organization-8.png"
                                            />
                                            <Box>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                黃家奎先生自1992年起加入建造業，擁有三十多年工程經驗。主力從事政府區域性工程為主，如馬路、行人路不同鐵器圍欄、各大小公共空間，如避雨亭或球埸設備、鋼結構臨時行人通道或車輛通道和永久特色標誌工程項目等。
                                                </Typography>
                                                <br/><br/>
                                                <Typography sx={{ ml: 4, fontSize: "1.25rem" }}>
                                                除了金屬工程業務外，黃先生亦有接觸標書計算、工程安全管理及施工協助等。
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Box>
                </Grid>
            </Root>
        </RootBox>
    )

}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PREFIX = "contact-us";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.position ? props.position : "center"}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const SubTitle = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.subtitle ? props.subtitle : "center"}
                >
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}


const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.about`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 3,
        width: "100%"
        // marginBottom: 10
    },

}));

export default OrganiztionPage;
