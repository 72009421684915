import './Editor.css';
// import classes from "./ActivityForm.module.css";

import React, { useState, useMemo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { ImageUpload }from '../../util/ImageUpload';
import Swal from 'sweetalert2';

Quill.register("modules/imageResize", ImageResize);



function Editor({content, handleContent, handleImages, auth}) {
  const [value, setValue] = useState(content ? content : '');

  const handleOnChange = (value) => {
    setValue(value);
    handleContent(value);
  }

  function imageHandler() {
    console.log('start image handler')
    const input = document.createElement('input');
  
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
  
    input.onchange = async () => {
        const file = input.files[0];
        const formData = new FormData();
  
        formData.append('file', file);
  
        // Save current cursor state
        const range = this.quill.getSelection(true);
  
        // Insert temporary loading placeholder image
        this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);
  
        // Move cursor to right side of image (easier to continue typing)
        this.quill.setSelection(range.index + 1);
  
        const res = await ImageUpload(formData, auth);
        // const res = "111"
        //const res = await apiPostNewsImage(formData); // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
        console.log('res: ' + res);

        if (!res) {
          console.log('no images');
          Swal.fire({
            text: '圖片未能上載',
            icon: 'error',
            confirmButtonText: '關閉'
          })
        }
        // add image to parent component list
        handleImages(res);

        // Remove placeholder image
        this.quill.deleteText(range.index, 1);
  
        // Insert uploaded image
        // this.quill.insertEmbed(range.index, 'image', res.body.image);
        this.quill.insertEmbed(range.index, 'image', res);
    };
  }
  
  
  var formats = [
    'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'align',
    'list', 'bullet', 'indent', 'stroke',
    'link', 'image', 'color', 'size', 'clean', 'code-block'
  ];
  
  const modules = useMemo(() => ({
    toolbar: {
        container: [
            [{ header: '1' }, { header: '2' }, { header: [3, 4, 5] }, { font: [] }],
            [{ size: [] }],
            [{ color: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean', 'code-block'],
        ],
        handlers: {
          // 'image': () => { imageHandler() }
          image: imageHandler
        }
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    },
  }), [])

  /* for debug */
  const showCode = () => {
    console.log('code: ' + value);
  }

  return (
      <ReactQuill 
        value={value} 
        onChange={handleOnChange} 
        modules={modules}
        bounds={"#root"}
      />
  );
}

export default Editor;