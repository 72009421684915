import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StorefrontIcon from '@mui/icons-material/Storefront';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { styled } from "@mui/material/styles";
import Button from '@mui/material/Button';
import { AuthProvider, useAuthState } from "../store/app-context";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../index.css'
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import withRoot from '../withRoot';
import { API_URL } from '../environment';
// import ReactPlayer from 'react-player/youtube';
import ReactPlayer from 'react-player/file';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function createProductData(id, name, image, unitPrice, category, size, description, weight, shipmentFee, images, availability, lastModifiedDate) {
  return { name, id, image, unitPrice, category, size, description, weight, shipmentFee, images, availability, lastModifiedDate };
}

let productData = [

]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: { xs: 1, md: 2 } }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function HomePage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false); // Temp: false
  const [item, setItem] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [loadProduct, setProduct] = useState([]);
  const [contentDetail, setContentDetail] = useState([]);
  const [value, setValue] = React.useState(0);

  const auth = useAuthState();
  //console.log('auth: ' + JSON.stringify(auth));

  useEffect(() => {
    // productData = [];
    // const urls = [
    //   API_URL + `/api/product/latest?limit=4`
    // ]
    // try {
    //   let products;

    //   const getProduct = async () => {
    //     [products] = await Promise.all(
    //       urls.map((url) => fetch(url)
    //         .then((res) => { return res.json(); })
    //       )
    //     );
    //     setIsLoading(false);
    //     setProduct(products);

    //     for (var p of products) {
    //       let id = p['productId'];
    //       let category = p['category']['categoryName'];
    //       let name = p['productName'];
    //       let image = p['productImages'][0]['imageUrl'];
    //       let unitPrice = p['productPrice'];
    //       let size = p['productSize'];
    //       let description = p['productDescription'];
    //       let weight = p['productWeight'];
    //       let availability = p['availability'];
    //       let shipmentFee = p['productShipmentFee'];
    //       let lastModifiedDate = p['lastModifiedDate'];

    //       let images = [];
    //       for (var i of p['productImages']) {
    //         images.push(i['imageUrl']);
    //       }

    //       productData.push(createProductData(id, name, image, unitPrice, category, size, description, weight, shipmentFee, images, availability, lastModifiedDate));
    //     }
    //   }
    //   getProduct();

    // } catch (error) {
    //   console.log(error);
    // }

  }, []);

  // if (isLoading) {
  //   return (
  //     <Backdrop
  //       sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //       open={isLoading}
  //     >
  //       <CircularProgress color="inherit" />
  //     </Backdrop>
  //   )
  // }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCheckoutClick = (item) => {
    setOpen(true);
    setItem(item);
  };

  const handleClose = () => {
    setOpen(false);
    setItem(null);
  };

  const toCheckoutPage = (item) => {
    navigate('/checkout', {
      state: {
        item: item
      }
    });
    // navigate(0); // force to reload page
  }

  const toCategoryPage = (categoryId) => {
    window.location = '/collection/' + categoryId;
  }

  const toProductDetailPage = (item) => {
    navigate('/product-detail/' + item.id + '/' + item.name, {
      state: {
        item: item
      }
    });
    // navigate(0); // force to reload page
  }

  return (
    <React.Fragment>
      <Box sx={{ mt: {xs: 6.5, md: 11} }}>
        <BannerList />
      </Box>
      <Box sx={{ mt: 1 }} >
        <Grid container spacing={{ xs: 1, md: 1 }} >
          <Grid item xs={12} sm={12} md={8}>
            <Box sx={{ mt: 1, mx: { xs: 1, md: 5 } }} >
              <Title title={"最新資訊"} position={{ xs: "left", md: "left" }} />
            </Box>
            <Box>
              <LatestInfoList />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} sx={{ backgroundColor: "#f1f1f1" }}>
            <Box sx={{ mt: 1, mb: 3, mx: { xs: 1 } }}>
              <Title title={"短片動態"} position={{ xs: "center", md: "left" }} variant={"h5"} />
              <Box sx={{ mt: 6 }}>
                <VideoSection />
              </Box> 
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>

  )
}

// let carpark = '[{"id":737,"name":"天水圍運動場","carparkId":"tdc65p3","district":"元朗區","tel":"9549 9869  / 2912 3700 / 2912 3719 / 2912 3713","address":"天水圍天瑞路2號","latitude":22.454557,"longitude":114.00469,"carparkPhoto":null,"lastModifiedDate":"2023-01-30T07:17:17.000+00:00", "image":"https://travelmail.in/wp-content/uploads/2023/02/hello-hong-kong.jpeg"},{"id":490,"name":"T Town North  停車場","carparkId":"tdc14p3306","district":"元朗區","tel":"3471 2340","address":"新界元朗天水圍天華路33及39號天悅邨停車場","latitude":22.462465,"longitude":113.99894,"carparkPhoto":null,"lastModifiedDate":"2023-01-30T07:17:17.000+00:00", "image":"https://www.royalplaza.com.hk/wp-content/uploads/RPH_Room_Package_Hello_HK-01.jpg"},{"id":454,"name":"健明A停車場","carparkId":"tdc14p1006","district":"西貢區","tel":"2830 3845","address":"新界將軍澳調景嶺彩明街1號健明邨停車場","latitude":22.306028,"longitude":114.25111,"carparkPhoto":null,"lastModifiedDate":"2023-01-30T07:17:17.000+00:00", "image": "https://cdn.i-scmp.com/sites/default/files/d8/images/canvas/2023/02/03/af2696fa-6748-4953-b57e-104fe1f9bb5e_0e98c3b5.jpg"},{"id":647,"name":"華富(一)邨","carparkId":"tdc32p1","district":"南區","tel":null,"address":"香港瀑布灣華富道","latitude":22.249779,"longitude":114.137024,"carparkPhoto":null,"lastModifiedDate":"2023-01-30T07:17:17.000+00:00", "image": "https://www.isletforum.com/data/attachment/forum/202302/07/181351msxffbq4jhdo04yy.jpeg"}]';


function createInfoData(informationId, title, image) {
  return { informationId, title, image };
}

let infoDatas = [];

const LatestInfoList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [infos, setInfos] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const toInfomationDetailPage = (infoId) => {
    navigate(`/media/information/` + infoId);
  }

  useEffect(() => {
    const urls = [
      API_URL + `/api/information`
    ]
    try {
      let data;
      const getLatestInfo = async () => {
        [data] = await Promise.all(
          urls.map((url) => fetch(url)
            .then((res) => { return res.json(); })
            .catch((error) => {
              setIsLoading(false);
              setInfos([]);
              console.log(error);
          })
          )
        );
        setIsLoading(false);

        var item = [];
        var items = [];
        var count = 0;
        var total = 0;

        for (var info of data) {
          let informationId = info['informationId'];
          let title = info['title'];
          // let image = info['informationImages'] ? info['informationImages'][0]['imageUrl'] : `/img/temp-image.jpeg`;
          const images = []
          for (var i = 0; i < info['informationImages'].length; i++) {
            images.push(info['informationImages'][i]['imageUrl']);
          }


          item.push(createInfoData(informationId, title, images[0]));
          count++;
          total++;

          // 2D array, each sub array contain max 3 items
          if (isSmall) {
            if (count == 1) {
              infoDatas.push(item);
              item = [];
              count = 0;
            }
          } else {
            if (count == 3) {
              infoDatas.push(item);
              item = [];
              count = 0;
            }

            // TODO: for last row 
            // if (total == data) {
            //   infoDatas.push(item);
            // } 
          }
        }
        setInfos(items);
      }
      getLatestInfo();

    } catch (error) {
      console.log(error);
    }
  }, [isSmall]);

  if (isLoading) {
    return <></>
  }

  return (
    <React.Fragment>
      <Carousel 
        autoPlay={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        infiniteLoop={true}
        stopOnHover={false}
        interval={8000}
      >
        {
          infoDatas.map((item, i) => {
            return (
              <Box
                key={i}
                justifyContent="center"
                sx={{ mx: { md: 2 }, my: { md: 2 } }}
              >
                <Stack direction="row" alignItems="center" spacing={2} >
                  <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* {!isSmall ? */}
                      <>
                        {item[0] ?
                          <Grid item xs={12} sm={12} md={4}>
                            <InfoItem key={i + 1} item={item[0] ? item[0] : ""} toInfomationDetailPage={toInfomationDetailPage}/>
                          </Grid> : ''

                        }
                        {item[1] ?
                          <Grid item xs={12} sm={12} md={4}>
                            <InfoItem key={i + 1} item={item[1] ? item[1] : ""} toInfomationDetailPage={toInfomationDetailPage}/>
                          </Grid> : ''

                        }
                        {item[2] ?
                          <Grid item xs={12} sm={12} md={4}>
                            <InfoItem key={i + 1} item={item[2] ? item[2] : ""} toInfomationDetailPage={toInfomationDetailPage}/>
                          </Grid> : ''

                        }

                       </>
                    {/*  :
                      <Grid item xs={12} sm={12} md={4}>
                        <InfoItem key={i + 1} item={item[0]} toInfomationDetailPage={toInfomationDetailPage}/>
                      </Grid>
                    } */}
                  </Grid>
                </Stack>
              </Box>

            )
          })
        }
      </Carousel>
    </React.Fragment>
  )
}

const BannerList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [banners, setBanners] = useState([]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const urls = [
      API_URL + `/api/file/get-banners`
    ]
    try {
      let data;
      const getBanners = async () => {
        [data] = await Promise.all(
          urls.map((url) => fetch(url)
            .then((res) => { return res.json(); })
            .catch((error) => {
              setIsLoading(false);
              setBanners([]);
              console.log(error);
            })
          )
        );
        setIsLoading(false);
        setBanners(data);
      }
      getBanners();

    } catch (error) {
      console.log(error);
    }
  }, []);

  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <React.Fragment>
      <Carousel
        showArrows={false}
        autoPlay={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        infiniteLoop={true}
        stopOnHover={false}
        interval={5000}
      >
        {
          banners.map((item, i) => {
            return (
              <Box 
                key={i} 
                justifyContent="center"
                alignItems="center"
                sx={{ maxHeight: { xs: 300, sm: 300, md: 650 } }}>
                <img src={item} style={{ objectFit: "cover" }} />
              </Box>
            )
          })
        }

      </Carousel>
    </React.Fragment>
  )
}

const VideoSection = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [video, setVideo] = useState([]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const urls = [
      API_URL + `/api/file/get-homepage-video`
    ]
    try {
      let data;
      const getVideo = async () => {
        [data] = await Promise.all(
          urls.map((url) => fetch(url)
            .then((res) => { return res.json(); })
            .catch((error) => {
              setIsLoading(false);
              setVideo([]);
              console.log(error);
            })
          )
        );
        setIsLoading(false);
        setVideo(data);
      }
      getVideo();

    } catch (error) {
      console.log(error);
    }
  }, []);

  if (isLoading) {
    return (
      <></>
    )
  }

  return (
    <ReactPlayer
      url={video[0]}
      controls={true}
      loop={true}
      width="100%"
      height="20rem"
    />
  )
}

const InfoItem = ({item, toInfomationDetailPage}) => {
  return (
    <CardScale 
      variant="outlined" 
      onClick={() => {toInfomationDetailPage(item['informationId'])}}
      sx={{ maxHeight: { xs: '30rem', sm: '30rem', md: '30rem' }, 
      position: 'relative' }}
      >
      <CardMedia
        component="img"
        alt={item['informationId']}
        height="275px"
        image={item['image']}
        sx={{ objectFit: "contain" }}
      />
      <CardContent>
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">{item['title']}</Typography>
      </CardContent>

    </CardScale>

  )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
  [`&.HotProduct`]: {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.primary.main
        : theme.palette.primary.main,
    borderBottomWidth: 3,
    [theme.breakpoints.up('xs')]: {
      marginLeft: '3em',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '5em',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '6em',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '4.5em',
    },
    // marginBottom: 20
  },
  [`&.HotCollection`]: {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.primary.main
        : theme.palette.primary.main,
    borderBottomWidth: 3,
    // marginBottom: 10
  },

}));

const ImageGalleryList = styled('ul')(({ theme }) => ({
  display: 'grid',
  padding: 0,
  // margin: theme.spacing(0, 4),
  gap: 8,
  [theme.breakpoints.up('xs')]: {
    gridTemplateRows: 'repeat(9, 150px)',
    gridTemplateColumns: 'repeat(2, 1fr)'
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateRows: 'repeat(3, 200px)',
    gridTemplateColumns: 'repeat(4, 1fr)'
  },
}));

const PREFIX = "district";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    // width: '100%',
    height: '100%',
    backgroundColor: theme.palette.mode === 'dark'
      ? '#1A2027'
      : '#fff',
    background:
      theme.palette.mode === "dark"
        ? theme.palette.background.primary
        : "#fff",
    color:
      theme.palette.mode === "dark"
        ? "#fff"
        : theme.palette.text.primary,
    divider:
      theme.palette.mode === "dark"
        ? "#fff"
        : theme.palette.text.primary,
  },
  [`&.${classes.loading}`]: {
    height: '90vh',
    background:
      theme.palette.mode === "dark"
        ? theme.palette.background.primary
        : "#fff",
    color:
      theme.palette.mode === "dark"
        ? "#fff"
        : theme.palette.text.primary,
    divider:
      theme.palette.mode === "dark"
        ? "#fff"
        : theme.palette.text.primary,
  },
}));

const RootBox = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === 'dark'
    ? '#1A2027'
    : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
  variant: 'outlined',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.primary,
}));

const Title = (props) => {
  return (
    <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
      <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Grid item xs={12} sm={12} md={12}
          container
          spacing={0}
          direction="row"
          justifyContent={props.position ? props.position : "center"}
        >
          <Typography
            variant={props.variant ? props.variant : "h5"}
            component="div"
            sx={{ fontWeight: 'bold', fontSize: "1.75rem" }}
          // sx={{ flexGrow: { xs: 1, md: 0 } }} // mr: 2, display: { xs: 'none', md: 'flex' }
          >
            {props.title}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

const SubTitle = (props) => {
  return (
    <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
      <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Grid item xs={12} sm={12} md={12}
          container
          spacing={0}
          direction="row"
          justifyContent="center"
        >
          <Typography
            variant="subtitle1"
            component="div"
          // sx={{ flexGrow: { xs: 1, md: 0 } }} // mr: 2, display: { xs: 'none', md: 'flex' }
          >
            {props.subtitle}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

const CardScale = styled(Card)(({ theme }) => ({
  "&:hover": {
    transition: "transform 0.15s ease-in-out",
    transform: "scale3d(1.05, 1.05, 1)"
  },
}));

export default withRoot(HomePage);