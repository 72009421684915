import { useState, useEffect, forwardRef, Fragment } from "react";
// React Router
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from "@mui/material/styles";
import { API_URL } from '../environment';
import { toDateString } from "../util/TimeUtil";
import Breadcrumb from "../components/layout/Breadcrumb";

const breadcrumbData = ["主頁", "資訊中心", "最新資訊"];


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#f1f1f1',
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(id, date, title) {
    return { id, date, title };
}

const rows = [
    createData('11', '2023-03-23', '「香港天文台140周年」專題網頁正式推出'),
    createData('12', '2023-03-22', '天文台加強分區相對濕度資訊服務'),
    createData('13', '2023-03-21', '二零二二年十一月的熱帶氣旋概述'),
    createData('14', '2023-03-20', '『氣象冷知識』：跟我返工報天氣'),
    createData('15', '2023-03-19', '香港天文台開放日2023'),
    createData('16', '2023-02-23', '『氣象冷知識』：2022世界天氣『氣象冷知識』：2022世界天氣'),
    createData('17', '2023-02-22', '『氣象冷知識』：碳的多重宇宙'),
    createData('18', '2023-02-21', '颱風納沙(2220)的報告'),
    createData('19', '2023-02-20', '二零二二年十月的熱帶氣旋概述 二零二二年十月的熱帶氣旋概述 二零二二年十月的熱帶氣旋概述'),
    createData('20', '2023-02-19', '溫暖的2022年'),
];

// let informationData = [];


function LatestInfomationPage(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [infos, setInfos] = useState([])
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { type } = useParams();
    let url = '';
    let title = '';
    let data

    const toContentPage = (id) => {
        navigate('/media/information/' + id);
        //navigate(0); // force to reload page
    }

    url = API_URL + `/api/information`;

    useEffect(() => {
        const urls = [url]
        try {
            const getResult = async () => {
                [data] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                        .catch((error) => {
                            setIsLoading(false);
                            setInfos([]);
                            console.log(error);
                        })
                    )
                );
                setIsLoading(false);

                var informationData = [];

                for (var i of data) {
                    let id = i['informationId'];
                    let title = i['title'];
                    let createdDate = i['createdDate'];

                    informationData.push(createData(id, createdDate, title));
                }
                setInfos(informationData)
            }
            getResult();

        } catch (error) {
            console.log(error);
        }

    }, []);


    if (isLoading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <RootBox>
            <Root className={classes.root}>
                <Box sx={{ mt: {xs: 8.25, md: 12.5} }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Box sx={{ my: 4 }}>
                    <Typography variant="h4">最新資訊</Typography>
                </Box>
                <Box sx={{ mb: 2 }}>{/*sx={{ backgroundColor: "#f1f1f1"}}*/}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: { md: 700 } }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell><Typography>日期</Typography></StyledTableCell>
                                    <StyledTableCell align="left"><Typography>事項</Typography></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                    infos.map((row) => (
                                        <StyledTableRow key={row.id}>
                                            <StyledTableCell component="th" scope="row" sx={{ width: {xs: 150, md: 170} , minWidth: { xs: 150 } }}>
                                                <Typography>{toDateString(new Date(row.date))}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <Link href="" onClick={(e) => { e.preventDefault(); toContentPage(row.id)}} underline="hover" color="#985b10">
                                                    <Typography
                                                        sx={{
                                                            display: '-webkit-box',
                                                            overflow: 'hidden',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: 2,
                                                        }}
                                                        gutterBottom variant="subtitle1" component="div">
                                                        {row.title}
                                                    </Typography>
                                                </Link>
                                            </StyledTableCell>
                                        </StyledTableRow> 
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Root>
        </RootBox>
    )
}

const PREFIX = "catgoryResult";

const classes = {
    root: `${PREFIX}-root`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        height: '100%',
        backgroundColor: theme.palette.mode === 'dark'
            ? '#1A2027'
            : '#fff',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '90vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : '#FFFFFF',
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

export default LatestInfomationPage;