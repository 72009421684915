import { API_URL } from '../environment';

// to FTP directory
export async function ImageUpload(formData, auth) {

    try {
        // const d = new FormData();
        // d.append('file', activityData.image[0]);
        let imageUrl;
        await fetch(
            API_URL + "/api/file/ftp-upload",
            {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': 'Bearer ' + auth.token,
                },
            }
        )
            .then(res => res.json())
            .then((result) => {
                console.log('result.data.link: ' + result.fileDownloadUri);
                imageUrl = result.fileDownloadUri;
            })
        return imageUrl;
    } catch (error) {
        console.error(error);
        return false;
    }

}
