import React, { useState, useEffect, forwardRef, Fragment, useRef } from "react";
import {
    Container, Slide, Box, Divider, Typography, Grid, Paper, TextField, Button, Backdrop, CircularProgress
} from "@mui/material";
import MuiLink from '@mui/material/Link';
import { styled } from "@mui/material/styles";
import "../index.css";
import Breadcrumb from "../components/layout/Breadcrumb";
import { useTranslation } from 'react-i18next';

// React Router
import { useNavigate } from "react-router-dom";

const breadcrumbData = ["主頁", "規章制度", "誠信及操守"];

function EthicsPage(props) {
    const { t, i18n } = useTranslation();

    return (
        <RootBox sx={{ minHeight: 'calc(100vh - 210px)' }}>
            <Root className={classes.root}>
                <Box sx={{ mt: { xs: 8.25, md: 12.5 }}}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h4">{t('system.ethics.title')}</Typography>
                </Box>
                <CustomDivider className={'ethics'} />
                <Box sx={{ mt: 4.5, textDecoration: 'underline' }}>
                    <Typography variant="h5">
                    {t('system.ethics.subtitle1')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ ml: 4 }}>
                    {t('system.ethics.subtitle2')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">
                    {t('system.ethics.society.title')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ ml: 4 }}>
                    {t('system.ethics.society.1')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ ml: 4 }}>
                    {t('system.ethics.society.2')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ ml: 4 }}>
                    {t('system.ethics.society.3')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">
                    {t('system.ethics.group.title')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ ml: 4 }}>
                    {t('system.ethics.group.1')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ ml: 4 }}>
                    {t('system.ethics.group.2')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ ml: 4 }}>
                    {t('system.ethics.group.3')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">
                    {t('system.ethics.customer.title')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ ml: 4 }}>
                    {t('system.ethics.customer.1')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ ml: 4 }}>
                    {t('system.ethics.customer.2')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="body1" sx={{ ml: 4 }}>
                    {t('system.ethics.customer.3')}
                    </Typography>
                </Box>
            </Root>
        </RootBox>
    )
}

const PREFIX = "ethics";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.position ? props.position : "center"}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const SubTitle = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.subtitle ? props.subtitle : "center"}
                >
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.ethics`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 2,
        width: "8rem"
        // marginBottom: 10
    },

}));

const ScaleCard = styled(Box)(({ theme }) => ({
    "&:hover": {
        transition: "transform 0.5s ease-in-out",
        transform: "scale3d(1.05, 1.05, 1)"
    },
}));

export default EthicsPage;