import React, { useState, useEffect, forwardRef, Fragment } from "react";
import { useParams } from 'react-router-dom';

import { CircularProgress, Container, Slide, Box, Divider, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import "../index.css";
// import SocialMediaBar from "../components/SocialMedia"
// import CarParkImageList from "../components/carpark/CarParkImageList";
import { toDateString } from '../util/TimeUtil';
import Backdrop from '@mui/material/Backdrop';
import 'leaflet/dist/leaflet.css';
import { API_URL } from '../environment';
import Breadcrumb from "../components/layout/Breadcrumb";

const breadcrumbData = ["主頁", "活動", "活動詳情"];

// proxy
// https://stackoverflow.com/questions/65427633/fetch-data-from-local-api

function ActivityContentPage(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [activityDetail, setActivityDetail] = useState([]);
    const [open, setOpen] = useState(false);
    const { Id, type } = useParams();

    useEffect(() => {
        const urls = [
            API_URL + `/api/activity/id/` + Id
        ]
        try {
            let activityDetail;
            const getActivityData = async () => {
                [activityDetail] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setActivityDetail(activityDetail);

            }

            getActivityData();

        } catch (error) {
            console.log(error);
        }

    }, []);

    if (isLoading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

        return (
            <RootBox>
                <Root className={classes.root}>
                    <Box sx={{ mt: {xs: 8.25, md: 12.5} }}>
                        <Breadcrumb breadcrumbData={breadcrumbData} />
                    </Box>
                    <Box sx={{ mt: 4 }}> 
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <MainContent activityDetail={activityDetail} />
                            </Grid>
                        </Grid>
                    </Box>
                </Root>
            </RootBox>
        )

}

const MainContent = (props) => {
    return (
        <>
            <Box sx={{ mt: 1, mb: 1 }}>
                <Typography sx={{ typography: { sm: 'h4', xs: 'h4', md: 'h4' } }}>活動</Typography>
            </Box>
            <Box sx={{ py: 1 }}>
                <Typography sx={{ typography: { sm: 'subtitle2', xs: 'subtitle2', md: 'subtitle1' } }}>{toDateString(new Date(props.activityDetail.createdDate))}</Typography>
            </Box>
            <CustomDivider className={'title'} />
            <Box sx={{ mt: 2, py: 1 }}>
                <Typography sx={{ typography: { sm: 'h5', xs: 'h5', md: 'h4' } }}>{props.activityDetail.title}</Typography>
            </Box>
            {/* <SocialMediaBar url={window.location.href} title={props.activityDetail.title} /> */}
            <Box sx={{ py: 1 }}>
                <div className="activity-article ql-editor" dangerouslySetInnerHTML={{ __html: props.activityDetail.content }}></div>
            </Box>
            <Divider variant="middle" />
            {/* <SocialMediaBar url={window.location.href} title={props.activityDetail.title} /> */}
        </>
    )
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PREFIX = "carparkInfo";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        paddingBottom: '50px',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.title`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#c9bea8',
        borderBottomWidth: 3,
        width: "100%"
    },

}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

export default ActivityContentPage;