import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { AuthProvider, useAuthState } from "./store/app-context";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { AppContextProvider } from './store/app-context';
import ScrollToTop from './util/ScrollToTop';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  }
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppContextProvider>
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </AuthProvider>
  </AppContextProvider>
);


// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
