import { Route, Routes, Navigate, useLocation  } from "react-router-dom";
import { useEffect, useContext, useMemo, useState } from "react";

// Pages
import PrivacyPage from "./pages/Privacy";
import PresidentSpeechPage from "./pages/PresidentSpeech";
import IntroductionPage from "./pages/Introduction";
import ActivityNewPage from "./pages/ActivityNew";
import ActivityHistoryPage from "./pages/ActivityHistory";
import ActivityContentPage from "./pages/ActivityContent";
import LatestInformationPage from "./pages/LatestInformation";
import InformationContentPage from "./pages/InformationContent";
import PressReleasePage from "./pages/PressReleases";
import ContactUsPage from "./pages/ContactUs";
import EnquiryPage from "./pages/Enquiry";
import OrganizationPage from "./pages/Organization";
import CommitteePage from "./pages/Committee";
import ConsultantPage from "./pages/Consultant";
import MemberBenefitsPage from "./pages/MemberBenefits";
import MemberListPage from "./pages/MemberList";
import BecomeMemberPage from "./pages/BecomeMember";
import RulesPage from "./pages/Rules";
import EthicsPage from "./pages/Ethics";
import HomeManagementPage from "./pages/management/HomeManagement";
import ActivityManagementPage from "./pages/management/ActivityManagement";
import InformationManagementPage from "./pages/management/InformationManagement";
import PressReleaseManagementPage from "./pages/management/PressReleaseManagement";
import CommitteeManagementPage from "./pages/management/CommitteeManagement";
import SettingsPage from "./pages/Settings";
import LoginPage from "./pages/Login";
import Terms from "./pages/Terms"
import HomePage from "./pages/Home";
import withRoot from './withRoot';
// Components
import Layout from "./components/layout/Layout";
import Footer from './components/layout/Footer';
import Header from "./components/layout/Header";
import ManagementHeader from "./components/layout/ManagementHeader"

// Context
import AppContext from './store/app-context';
import { AuthProvider, useAuthState } from "./store/app-context";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import './index.css'
import './i18n';

function App() {
  const auth = useAuthState();
  const [open, setOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const location = useLocation();

  const { colorMode } = useContext(AppContext);
  const theme = useMemo(() => {
    return createTheme(getThemeTokens(colorMode), [colorMode])
  }, [colorMode]);

  const handleLoginBtnClick = () => {
    console.log('login btn click');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const PrivateRoute = ({children}) => {
    const auth = useAuthState();
    console.log('auth: ' + JSON.stringify(auth));
    return (auth.userDetails || auth.user) ? children : <Navigate to="/" />;
  }

  useEffect(() => {
    if (auth.userDetails || auth.user) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth]);

  return (
    // <ColorModeContext.Provider value={colorMode}>
    // <StyledEngineProvider injectFirst>
    // <AuthProvider>
      <ThemeProvider theme={theme}>
        <div>
        {/* <AppAppBar/> */}
        {/* <Header handleLoginBtnClick={handleLoginBtnClick} /> */}
        {
          location.pathname.match('/management') && loggedIn ? <ManagementHeader/> : <Header/>
        }
          <Routes>
            <Route path="/" element={<HomePage />} exact />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/about/introduction" element={<IntroductionPage/>} />
            <Route path="/about/speech" element={<PresidentSpeechPage/>} />
            <Route path="/about/organization" element={<OrganizationPage />} />
            <Route path="/about/committee" element={<CommitteePage />} />
            <Route path="/about/consultant" element={<ConsultantPage />} />
            <Route path="/activity/latest" element={<ActivityNewPage />} />
            <Route path="/activity/history" element={<ActivityHistoryPage />} />
            <Route path="/activity/:Id" element={<ActivityContentPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/enquiry" element={<EnquiryPage />} />
            <Route path="/media/latest-information" element={<LatestInformationPage />} />
            <Route path="/media/information/:Id" element={<InformationContentPage />} />
            <Route path="/media/press-releases" element={<PressReleasePage />} />
            <Route path="/member/benefits" element={<MemberBenefitsPage/>} />
            <Route path="/member/member-list" element={<MemberListPage/>} />
            <Route path="/member/becomes-member" element={<BecomeMemberPage/>} />
            <Route path="/system/rules" element={<RulesPage/>} />
            <Route path="/system/integrity-and-ethics" element={<EthicsPage/>} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/terms" element={<Terms/>} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/management/" 
                    element={
                      <PrivateRoute>
                        <HomeManagementPage />
                      </PrivateRoute>
                    } 
            />
            <Route path="/management/activity" 
                    element={
                      <PrivateRoute>
                        <ActivityManagementPage />
                      </PrivateRoute>
                    } 
            />
            <Route path="/management/information" 
                    element={
                      <PrivateRoute>
                        <InformationManagementPage />
                      </PrivateRoute>
                    } 
            />
            <Route path="/management/press-release" 
                    element={
                      <PrivateRoute>
                        <PressReleaseManagementPage />
                      </PrivateRoute>
                    } 
            />
            <Route path="/management/committee" 
                    element={
                      <PrivateRoute>
                        <CommitteeManagementPage />
                      </PrivateRoute>
                    } 
            />
            <Route path="*" element={<p>Path not resolved</p>} />
          </Routes>
        {
          location.pathname.match('/management') && loggedIn ? null : <Footer/>
        }
        {/* <AppFooter/> */}
        </div>
      </ThemeProvider>
    // </AuthProvider>
    // </StyledEngineProvider>
    // </ColorModeContext.Provider>
  );
}

const getThemeTokens = (mode) => ({
  typography: {
    fontFamily: "'Noto Sans HK', sans-serif",
  },
  typography: {
    body1: {
        fontSize: ['1.3rem']
    },
    subtitle1: {
        fontSize: ['1.5rem']
    }
  },
  palette: {
    mode,
    ...(mode === "light"
      ? {
        // light mode: 
        background: {
          // default: "#f3c622",
          main: "#fff"
        },

      }
      : {
        // dark mode
        primary: {
          main: "#f3c622", // yellow
        },
        background: {
          default: "#121212",
          primary: "#1A2027"
        },
      }),
  },
})

export default withRoot(App);


// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
