import React, { useState, useEffect, forwardRef, Fragment } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import { CircularProgress, Container, Slide, Box, Divider, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import "../index.css";
// import SocialMediaBar from "../components/SocialMedia"
// import CarParkImageList from "../components/carpark/CarParkImageList";
import { toDateString } from '../util/TimeUtil';
import Backdrop from '@mui/material/Backdrop';
import 'leaflet/dist/leaflet.css';
import { API_URL } from '../environment';
import Breadcrumb from "../components/layout/Breadcrumb";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

const breadcrumbData = ["主頁", "資訊"];

// proxy
// https://stackoverflow.com/questions/65427633/fetch-data-from-local-api

function InformationContentPage(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [informationDetail, setInformationDetail] = useState([]);
    const [open, setOpen] = useState(false);
    const { Id, type } = useParams();

    useEffect(() => {
        const urls = [
            API_URL + `/api/information/id/` + Id
        ]
        try {
            let informationDetail;
            const getInformationData = async () => {
                [informationDetail] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setInformationDetail(informationDetail);

            }

            getInformationData();

        } catch (error) {
            console.log(error);
        }

    }, []);

    if (isLoading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <RootBox>
            <Root className={classes.root}>
                <Box sx={{ mt: {xs: 8.25, md: 12.5} }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <MainContent informationDetail={informationDetail} />
                        </Grid>
                    </Grid>
                </Box>
            </Root>
        </RootBox>
    )

}

const MainContent = (props) => {
    return (
        <>
            <Box sx={{ mt: 1, mb: 1 }}>
                <Typography sx={{ typography: { sm: 'h4', xs: 'h4', md: 'h4' } }}>資訊</Typography>
            </Box>
            <Box sx={{ py: 1 }}>
                <Typography sx={{ typography: { sm: 'subtitle2', xs: 'subtitle2', md: 'subtitle1' } }}>{toDateString(new Date(props.informationDetail.createdDate))}</Typography>
            </Box>
            <CustomDivider className={'title'} />
            <Box sx={{ mt: 2, p: 1 }}>
                <Typography sx={{ typography: { sm: 'h4', xs: 'h4', md: 'h4' } }}>{props.informationDetail.title}</Typography>
            </Box>
            <Box sx={{ p: 1 }}>
                <Typography component={'span'}><div className="activity-article" dangerouslySetInnerHTML={{ __html: props.informationDetail.content }}></div></Typography>
            </Box>
            <Box sx={{ p: 1 }}>
                <GalleryList images={props.informationDetail.informationImages} />
            </Box>
            <Divider variant="middle" />
        </>
    )
}

let carpark = '[{"id":737,"name":"天水圍運動場","carparkId":"tdc65p3","district":"元朗區","tel":"9549 9869  / 2912 3700 / 2912 3719 / 2912 3713","address":"天水圍天瑞路2號","latitude":22.454557,"longitude":114.00469,"carparkPhoto":null,"lastModifiedDate":"2023-01-30T07:17:17.000+00:00", "image":"https://travelmail.in/wp-content/uploads/2023/02/hello-hong-kong.jpeg"},{"id":490,"name":"T Town North  停車場","carparkId":"tdc14p3306","district":"元朗區","tel":"3471 2340","address":"新界元朗天水圍天華路33及39號天悅邨停車場","latitude":22.462465,"longitude":113.99894,"carparkPhoto":null,"lastModifiedDate":"2023-01-30T07:17:17.000+00:00", "image":"https://www.royalplaza.com.hk/wp-content/uploads/RPH_Room_Package_Hello_HK-01.jpg"},{"id":454,"name":"健明A停車場","carparkId":"tdc14p1006","district":"西貢區","tel":"2830 3845","address":"新界將軍澳調景嶺彩明街1號健明邨停車場","latitude":22.306028,"longitude":114.25111,"carparkPhoto":null,"lastModifiedDate":"2023-01-30T07:17:17.000+00:00", "image": "https://cdn.i-scmp.com/sites/default/files/d8/images/canvas/2023/02/03/af2696fa-6748-4953-b57e-104fe1f9bb5e_0e98c3b5.jpg"},{"id":647,"name":"華富(一)邨","carparkId":"tdc32p1","district":"南區","tel":null,"address":"香港瀑布灣華富道","latitude":22.249779,"longitude":114.137024,"carparkPhoto":null,"lastModifiedDate":"2023-01-30T07:17:17.000+00:00", "image": "https://www.isletforum.com/data/attachment/forum/202302/07/181351msxffbq4jhdo04yy.jpeg"}]';

const GalleryList = ({ images }) => {
    const [loadCarpark, setCarpark] = useState([]);
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Grid item
                container
                spacing={0}
                direction="row"
                justifyContent="center"
            >
                <Grid container item spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {!isSmall ?
                        <>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box
                                    component="img"
                                    sx={{
                                        // height: 233,
                                        // width: 350,
                                        maxWidth: '50vw'
                                    }}
                                    src={images[0] ? images[0].imageUrl : ""}
                                    alignItems="center"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box
                                    component="img"
                                    sx={{
                                        // height: 233,
                                        // width: 350,
                                        maxWidth: '50vw'
                                    }}
                                    src={images[1] ? images[1].imageUrl : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box
                                    component="img"
                                    sx={{
                                        // height: 233,
                                        // width: 350,
                                        maxWidth: '100vw'
                                    }}
                                    src={images[3] ? images[3].imageUrl : ""}
                                />
                            </Grid>
                        </>
                        : <Grid item xs={12} sm={12} md={12}>
                            <Link key="1" color="inherit" href={images[0] ? images[0].imageUrl : "#"} target="_blank">
                            <Box
                                component="img"
                                sx={{
                                    // height: 233,
                                    // width: 350,
                                    maxWidth: '80vw'
                                }}
                                src={images[0] ? images[0].imageUrl : ""}
                            >
                            </Box>
                            </Link>
                            <Link key="1" color="inherit" href={images[0] ? images[0].imageUrl : "#"} target="_blank">
                            <Box
                                component="img"
                                sx={{
                                    mt: 2,
                                    // height: 233,
                                    // width: 350,
                                    maxWidth: '80vw'
                                }}
                                src={images[1] ? images[1].imageUrl : ""}
                            >
                            </Box>
                            </Link>
                            <Link key="1" color="inherit" href={images[0] ? images[0].imageUrl : "#"} target="_blank">
                            <Box
                                component="img"
                                sx={{
                                    mt: 2,
                                    // height: 233,
                                    // width: 350,
                                    maxWidth: '80vw'
                                }}
                                src={images[2] ? images[2].imageUrl : ""}
                            >
                            </Box>
                            </Link>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </div>


    )
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PREFIX = "carparkInfo";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        paddingBottom: '50px',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.title`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#c9bea8',
        borderBottomWidth: 3,
        width: "100%"
    },

}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

export default InformationContentPage;