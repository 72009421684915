import React, { useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import SettingsIcon from "@mui/icons-material/Settings";
import LocalParkingIcon from "@mui/icons-material/LocalParking"
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import InfoIcon from '@mui/icons-material/Info';
import MapIcon from '@mui/icons-material/Map';
import HomeIcon from "@mui/icons-material/Home";
import Cloud from '@mui/icons-material/Cloud';
import { styled, alpha } from "@mui/material/styles";
import { Link, useLocation, useHistory, useNavigate } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "../../store/app-context";
import { logout } from "../../store/action";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Collapse from '@mui/material/Collapse';
import StarBorder from '@mui/icons-material/StarBorder';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import LanguageIcon from '@mui/icons-material/Language';
import InputBase from '@mui/material/InputBase';
import ScrollTop from '../../util/ScrollTop';
import HideOnScroll from '../../util/HideOnScroll';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/ArrowUpward';
import { popoverClasses } from "@mui/material/Popover" 
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import AppContext from "../../store/app-context";

const ManagementHeader = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
  const [anchorElAbout, setAnchorElAbout] = React.useState(null);
  const [anchorElActivity, setAnchorElActivity] = React.useState(null);
  const [anchorElInfo, setAnchorElInfo] = React.useState(null);
  const [anchorElMember, setAnchorElMember] = React.useState(null);
  const [anchorElManagement, setAnchorElManagement] = React.useState(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openNested, setOpenNested] = React.useState(false);
  const [openNested2, setOpenNested2] = React.useState(false);

  const { languageMode, toggleLanguageMode } = useContext(AppContext);
  const  [ language, setLanguage] = useState(localStorage.getItem("languageMode") === 'en' ? 'English' : '中文');  

  const auth = useAuthState();
  const dispatch = useAuthDispatch();
  const [loggedIn, setLoggedIn] = React.useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const onKeyDownSearchHandler = (event) => {
    if(event.keyCode == 13){
        let keyword = event.target.value
        navigate('/search/' + keyword);
        navigate(0);
    }
}

  const handleNestedClick = () => {
    setOpenNested(!openNested);
  };

  const handleNestedClick2 = () => {
    setOpenNested2(!openNested2);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenLanguageMenu = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };
  const handleOpenAboutMenu = (event) => {
    setAnchorElAbout(event.currentTarget);
  };
  const handleOpenActivityMenu = (event) => {
    setAnchorElActivity(event.currentTarget)
  }
  const handleOpenInfoMenu = (event) => {
    setAnchorElInfo(event.currentTarget)
  }
  const handleOpenMemberMenu = (event) => {
    setAnchorElMember(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenManagementMenu = (event) => {
    setAnchorElManagement(event.currentTarget)
  }

  const handleCloseManagementMenu = () => {
    setAnchorElManagement(null);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorElLanguage(null);
  };

  const handleCloseAboutMenu = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setAnchorElAbout(null);
      }
    }, 50);
  };

  const handleCloseActivityMenu = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setAnchorElActivity(null);
      }
    }, 50);
  };

  const handleCloseInfoMenu = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setAnchorElInfo(null);
      }
    }, 50);
  };

  const handleCloseMemberMenu = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setAnchorElMember(null);
      }
    }, 50);
  };

  let currentlyHovering = false;
  function handleHover() {
    currentlyHovering = true;
  }

  const handleLogoutBtnClick = () => {
    logout(dispatch);
    window.location = '/';
  };

  const toCategoryPage = (categoryId) => {
    window.location = '/collection/' + categoryId;
  }

  React.useEffect(() => {
    if (auth.userDetails || auth.user) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const DrawerList = () => {
    return (
      <Box
        sx={{width: '250', height: '100%', bgcolor: '#f3f3f3'}}
        // role="presentation"
        // onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      >
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: '#f3f3f3' ,color: 'black'}}
          component="nav"
        >
          <ListItem disablePadding>
            <ListItemButton>
              <CloseIcon onClick={toggleDrawer}/>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
                onClick={() => navigate(`/management/`)}
            >
              <ListItemText primary="主頁" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
                onClick={() => navigate(`/management/activity`)}
            >
              <ListItemText primary="活動管理" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate(`/management/information`)}
            >
              <ListItemText primary="最新消息" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate(`/management/press-release`)}
            >
              <ListItemText primary="刊物管理" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate(`/management/committee`)}
            >
              <ListItemText primary="委員會管理" />
            </ListItemButton>
          </ListItem>
        </List>
        <List 
        sx={{ width: '100%', maxWidth: 360, bgcolor: '#f3f3f3' }}
          component="nav">
          <ListItem>
            <Grid item xs={12} sm={12} md={12}
              container
              spacing={0}
              direction="column"
              justifyContent="center">
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="搜尋..."
                  inputProps={{ 'aria-label': 'search' }}
                  onKeyDown={onKeyDownSearchHandler}
                />
              </Search>
            </Grid>
          </ListItem>
        </List>
      </Box>
    )
  }


  return (
    <React.Fragment>
    <HideOnScroll {...props}>
    <Root
      value={location.pathname.replace(/(.*)\/[0-9]*?$/, "$1")}
      // showLabels={true}
      classes={{ root: classes.root }}
    >
      <Box
        component="nav"
        sx={{ width: { sm: 240 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          // container={container}
          variant="temporary"
          anchor="left"
          open={openDrawer}
          onClose={toggleDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          <DrawerList />
        </Drawer>
      </Box>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* For mobile */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="language"
              aria-controls="menu-language"
              aria-haspopup="true"
              // onClick={handleOpenNavMenu}
              onClick={toggleDrawer}
              // color="inherit"
            >
              <MenuIcon />
            </IconButton>            
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ color: 'black', flexGrow: { xs: 1, md: 0 }, display: { xs: 'flex', md: 'flex' } }} // mr: 2, display: { xs: 'none', md: 'flex' }
          >
            HKMECA管理平台
          </Typography>

          {/* For desktop */}
          <Box justifyContent="flex-end" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
              sx={{ my: 1, color: 'black', display: 'block' }}
              onClick={() => navigate(`/management/`)}
            >
              <Typography sx={{ typography: 'subtitle1' }}>主頁</Typography>
            </Button>
            <Button
              sx={{ my: 1, color: 'black', display: 'block' }}
              onClick={() => navigate(`/management/activity`)}
            >
              <Typography sx={{ typography: 'subtitle1' }}>活動管理</Typography>
            </Button>
            <Button
              sx={{ my: 1, color: 'black', display: 'block' }}
              onClick={() => navigate(`/management/information`)}
            >
              <Typography sx={{ typography: 'subtitle1' }}>消息管理</Typography>
            </Button>
            <Button
              sx={{ my: 1, color: 'black', display: 'block' }}
              onClick={() => navigate(`/management/press-release`)}
            >
              <Typography sx={{ typography: 'subtitle1' }}>刊物管理</Typography>
            </Button>
            <Button
              sx={{ my: 1, color: 'black', display: 'block' }}
              onClick={() => navigate(`/management/committee`)}
            >
              <Typography sx={{ typography: 'subtitle1' }}>委員會管理</Typography>
            </Button>
            {loggedIn ? (
              <>
                <Button
                  onClick={handleLogoutBtnClick}
                  sx={{ my: 1, color: 'black', display: 'block' }}
                >
                  <Typography sx={{ typography: 'subtitle1' }}>登出</Typography>
                </Button>
              </>
            )
              : <></>
            }
          </Box>
        </Toolbar>
      </Container>
    </Root>
    </HideOnScroll>
    <Toolbar id="toolbar"/>
    <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
};

const PREFIX = "header";

const classes = {
  root: `${PREFIX}-root`,
  selected: `${PREFIX}-selected`,
};

const Root = styled(AppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.background.default
        : theme.palette.background.main,
    // position: "sticky",
    // width: "100%",
    // bottom: "0",
    // height: "initial",
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.light, 0.15),
  '&:hover': {
      backgroundColor: alpha(theme.palette.primary.light, 0.25),
  },
  marginLeft: 0,
  marginRight: '1.5em',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      // width: '100%',
      width: '12ch',
          '&:focus': {
              width: '14ch',
          },
      
  },
}));


export default ManagementHeader;
