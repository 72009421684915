import { useState, useEffect, forwardRef, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Editor from "../Editor";
import { useAuthState } from "../../../store/app-context";

// Hooks
import { useRef } from "react";

function NewActivityForm(props) {
    const [status, setStatus] = useState();
    const [content, setContent] = useState();
    const [images, setImages] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const titleInputRef = useRef();
    const contentInputRef = useRef();
    const imageInputRef = useRef();
    const auth = useAuthState();

    const handleContent = (content) => {
        setContent(content);
    };

    const handleImages = (image) => {
        console.log(image);
        setImages(images => [...images, image]);
        console.log('list: ' + images);
    }

    function submitHandler(event) {
        event.preventDefault();
        setBtnLoading(true);

        const title = titleInputRef.current.value;
        const createdDate = new Date();
        const lastModifiedDate = new Date();

        const activityData = {
            title: title,
            image: images,
            imageUrl: images,
            content: content,
            createdDate: createdDate,
            lastModifiedDate: lastModifiedDate
        };

        console.log(activityData);

        props.addActivityHandler(activityData);
        setBtnLoading(false);
    }

    const handleChange = (event) => {
        setStatus(event.target.value);
    };


    return (
        <Box sx={{ mt: 1, mb: 5 }} >
                <Box sx={{ my: 1 }}>
                    <label htmlFor="title">標題</label>
                    <TextField required id="title" inputRef={titleInputRef} size="small" sx={{ width: '100%' }} />
                </Box>
                <Box sx={{ my: 1 }}>
                    <label htmlFor="content">內容</label>
                    {/* <TextField required id="content" multiline rows={5} inputRef={contentInputRef} sx={{ width: '100%' }} /> */}
                    <Editor 
                        content={content} 
                        handleContent={handleContent} 
                        handleImages={handleImages}
                        activityImageUpload={props.activityImageUpload}
                        auth={auth}
                    />
                </Box>
                <Box sx={{ my: 1 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <LoadingButton 
                                variant="contained" 
                                color="warning"
                                loading={btnLoading}
                                loadingPosition="start"  
                                startIcon={<SaveIcon/>}
                                onClick={submitHandler}
                            >
                                <span>加入</span>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
                </Box>
        </Box>
    )
}

export default NewActivityForm;