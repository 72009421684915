import { useState, useEffect, forwardRef, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from "@mui/material/Typography";
// import classes from "./ActivityForm.module.css";
import Swal from 'sweetalert2';
import { API_URL } from '../../../environment';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from'@mui/icons-material/Save';
import Editor from '../Editor';
import { useAuthState } from "../../../store/app-context";

// Hooks
import { useRef } from "react";

function EditInformationForm(props) {
    const [status, setStatus] = useState();
    const [images, setImages] = useState(props.informationDetail.images);
    const [removeImage, setRemoveImage] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [content, setContent] = useState();
    const auth = useAuthState(); 

    const informationIdInputRef = useRef();
    const titleInputRef = useRef();
    const contentInputRef = useRef();
    const imageInputRef = useRef();
    
    const handleContent = (content) => {
        console.log('EditInformationForm: ' + content);
        setContent(content);
    };

    const handleImages = (image) => {
        console.log(image);
        console.log('original list: ' + images);
        setImages(images => [...images, image]);
        console.log('list: ' + images);
    }

    function submitHandler(event) {
        event.preventDefault();
        setBtnLoading(true);

        const informationId = informationIdInputRef.current.value;
        const title = titleInputRef.current.value;
        const content = contentInputRef.current.value;
        const createdDate = new Date();
        const lastModifiedDate = new Date();

        let images = [];
        const fileCount = document.getElementsByClassName('input-image')[0].files.length;
        for (var i = 0; i < fileCount; i++) {
            const tmpImage = document.getElementsByClassName('input-image')[0].files[i];
            images.push(tmpImage);
        }

        const informationData = {
            informationId: informationId,
            title: title,
            image: images,
            imageUrl: images,
            content: content,
            createdDate: createdDate,
            lastModifiedDate: lastModifiedDate,
            removeImage: removeImage
        };

        console.log(informationData);

        props.modifyInformationHandler(informationData);
    }

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const removeImageHandler = (imageId) => {

        setRemoveImage(image => [...image, imageId]);

        const newImages = [];
        for (var i of images) {
            if (i.imageId !== imageId) {
                newImages.push(i);
            } else {

            }
        }
        setImages(newImages);
    }

    return (
        <Box sx={{ mt: 1, mb: 5 }} >
                <Typography variant="h6" color="text.secondary">
                    更改
                </Typography>
                <Box sx={{ my: 1 }}>
                    <label htmlFor="informationId">編號</label>
                    <TextField required id="id" defaultValue={props.informationDetail.informationId} inputRef={informationIdInputRef} size="small" sx={{ width: '100%' }} InputProps={{ readOnly: true, }} />
                </Box>
                <Box sx={{ my: 1 }}>
                    <label htmlFor="title">標題</label>
                    <TextField required id="title" inputRef={titleInputRef} defaultValue={props.informationDetail.title} size="small" sx={{ width: '100%' }} />
                </Box>
                <Box sx={{ my: 1 }}>
                    <label htmlFor="image">相片</label>
                    <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
                        {images.map((item) => (
                            <Box sx={{ position: 'relative' }} key={item.imageId}>
                                <Box sx={{ my: 1 }}>
                                    <img src={item.imageUrl} alt={item.imageUrl} style={{ maxWidth: '100px' }} />
                                </Box>
                                <Box sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    bgcolor: 'rgba(0, 0, 0, 0.54)',
                                    color: 'white',
                                    // padding: '1px',
                                }}>
                                    <Grid container justifyContent="flex-end">
                                        <CloseIcon onClick={() => { removeImageHandler(item.imageId) }} />
                                    </Grid>
                                </Box>
                            </Box>
                        ))}
                    </Stack>
                    <input type="file" className="input-image" id="image" ref={imageInputRef} multiple />
                </Box>
                <Box sx={{ my: 1 }}>
                    <label htmlFor="content">內容</label>
                    <TextField required id="content" multiline rows={5} inputRef={contentInputRef} defaultValue={props.informationDetail.content} sx={{ width: '100%' }} />
                </Box>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={12} sm={2} md={6}>
                        <Button variant="contained" onClick={props.handleClose}>返回</Button>
                    </Grid>
                    <Grid item xs={12} sm={2} md={6}>
                        <Box display="flex" justifyContent="flex-end">
                            <LoadingButton 
                                variant="contained" 
                                color="warning"
                                loading={btnLoading}
                                loadingPosition="start"  
                                startIcon={<SaveIcon/>}
                                onClick={submitHandler}
                            >
                                <span>確定更改</span>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
        </Box>
    )
}

export default EditInformationForm;