import React, { useState } from "react";
import {
    Container, Box, Divider, Typography, Grid, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from "@mui/material/styles";
import "../index.css";
import Breadcrumb from "../components/layout/Breadcrumb";
import { useTranslation } from 'react-i18next';

const breadcrumbData = ["主頁", "規章制度", "會規"];

function RulesPage(props) {
    const { t, i18n } = useTranslation();
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <RootBox sx={{ minHeight: 'calc(100vh - 210px)' }}>
            <Root className={classes.root} >
                <Box sx={{ mt: { xs: 8.25, md: 12.5 } }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h4">{t('system.rules.title')}</Typography>
                </Box>
                <CustomDivider className={'rules'} />
                <Box sx={{ mt: 4, flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'flex' } }}>
                    <div>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Box>
                                    <Typography variant="subtitle1">
                                        {t('system.rules.eposide1.title')}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography sx={{ my: 1 }}>1. {t('system.rules.eposide1.1')}</Typography>
                                <Typography sx={{ my: 1 }}>2. {t('system.rules.eposide1.2')}</Typography>
                                <Typography sx={{ my: 1 }}>3. {t('system.rules.eposide1.3')}</Typography>
                                <Typography sx={{ my: 1 }}>4. {t('system.rules.eposide1.4')}</Typography>  
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Box>
                                    <Typography variant="subtitle1">
                                    {t('system.rules.eposide2.title')}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography varient="subtitl2" sx={{ my: 1 }}>{t('system.rules.eposide2.subtitle')}</Typography>
                                <Typography sx={{ my: 1 }}>1. {t('system.rules.eposide2.1')}</Typography>
                                <Typography sx={{ my: 1 }}>2. {t('system.rules.eposide2.2')}</Typography>
                                <Typography sx={{ my: 1 }}>3. {t('system.rules.eposide2.3')}</Typography>
                                <Typography sx={{ my: 1 }}>4. {t('system.rules.eposide2.4')}</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Box>
                                    <Typography variant="subtitle1">
                                    {t('system.rules.eposide3.title')}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography varient="body1" sx={{ my: 1 }}>1. {t('system.rules.eposide3.1.title')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.1.1')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.1.2')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>2. {t('system.rules.eposide3.2.title')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.2.1')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.2.2')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>3. {t('system.rules.eposide3.3.title')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.3.1')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.3.2')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.3.3')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>4. {t('system.rules.eposide3.4.title')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.4.1')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.4.2')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.4.3')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>5. {t('system.rules.eposide3.5.title')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.5.1')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.5.2')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.5.3')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.5.4')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide3.5.5')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>6. {t('system.rules.eposide3.6')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>7. {t('system.rules.eposide3.7')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>8. {t('system.rules.eposide3.8')}</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Box>
                                    <Typography variant="subtitle1">
                                    {t('system.rules.eposide4.title')}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography varient="body1" sx={{ my: 1 }}>1. {t('system.rules.eposide4.1.title')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.1')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.2')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.3')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.4')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.5')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.6')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.7')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.8')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.9')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.10')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.11')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.1.12')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>2. {t('system.rules.eposide4.2')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>3. {t('system.rules.eposide4.3')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>4. {t('system.rules.eposide4.4')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>5. {t('system.rules.eposide4.5.title')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.5.1')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.5.2')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.5.3')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.5.4')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.5.5')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>6. {t('system.rules.eposide4.6.title')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.6.1')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.6.2')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.6.3')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.6.4')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>7. {t('system.rules.eposide4.7.title')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.7.1')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.7.2')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.7.3')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide4.7.4')}</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5bh-content"
                                id="panel5bh-header"
                            >
                                <Box>
                                    <Typography variant="subtitle1">
                                    {t('system.rules.eposide5.title')}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography varient="body1" sx={{ my: 1 }}>1. {t('system.rules.eposide5.1.title')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide5.1.1')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide5.1.2')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide5.1.3')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide5.1.4')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide5.1.5')}</Typography>
                                <Typography varient="body2" sx={{ my: 1, ml: 2 }}>{t('system.rules.eposide5.1.6')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>2. {t('system.rules.eposide5.2')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>3. {t('system.rules.eposide5.3')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>4. {t('system.rules.eposide5.4')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>5. {t('system.rules.eposide5.5')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>6. {t('system.rules.eposide5.6')}</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel6'} onChange={handleAccordionChange('panel6')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel6bh-content"
                                id="panel6bh-header"
                            >
                                <Box>
                                    <Typography variant="subtitle1">
                                    {t('system.rules.eposide6.title')}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography varient="body1" sx={{ my: 1 }}>1. {t('system.rules.eposide6.1')}</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel7'} onChange={handleAccordionChange('panel7')} sx={{ mb: 3 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel6bh-content"
                                id="panel6bh-header"
                            >
                                <Box>
                                    <Typography variant="subtitle1">
                                    {t('system.rules.eposide7.title')}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography varient="body1" sx={{ my: 1 }}>1. {t('system.rules.eposide7.1')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>2. {t('system.rules.eposide7.2')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>3. {t('system.rules.eposide7.3')}</Typography>
                                <Typography varient="body1" sx={{ my: 1 }}>4. {t('system.rules.eposide7.4')}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Box>
            </Root>
        </RootBox>
    )
}

const PREFIX = "introdution";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.position ? props.position : "center"}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const SubTitle = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.subtitle ? props.subtitle : "center"}
                >
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.about`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.main,
        borderBottomWidth: 3,
        width: "100%"
        // marginBottom: 10
    },
    [`&.rules`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 2,
        width: "4rem"
        // marginBottom: 10
    },

}));

const ScaleCard = styled(Box)(({ theme }) => ({
    "&:hover": {
        transition: "transform 0.5s ease-in-out",
        transform: "scale3d(1.05, 1.05, 1)"
    },
}));

export default RulesPage;