import { API_URL } from '../environment';
const ROOT_URL = API_URL + '/api/login';

export async function loginUser(dispatch, loginPayload) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginPayload),
    };

    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await fetch(`${ROOT_URL}`, requestOptions);
        // response = "{user: {\"username\": \"sholdman\", \"token\": \"abc1234\"}}"
        let data = await response.json();
        console.log('data: ' + JSON.stringify(data));


        if (data.token) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: data });
            localStorage.setItem('currentUser', JSON.stringify(data));
            return data;
        }

        dispatch({ type: 'LOGIN_ERROR', error: '登入錯誤' });
        return;

    } catch (error) {
        console.log("error: " + error);
        dispatch({ type: 'LOGIN_ERROR', error: '登入錯誤' });
    }
}

export async function logout(dispatch) {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
}