import React, { useState, useEffect, forwardRef, Fragment } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from "@mui/material/styles";
import "../../index.css";
import { toDateString } from '../../util/TimeUtil';
// import { Key } from '../../imgurKey';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { API_URL } from '../../environment';
import axios from "axios";

// React Router
import { useNavigate } from "react-router-dom";

// Components
import NewPressReleaseForm from "../../components/management/press-release/NewPressReleaseForm";
import PressReleaseTable from "../../components/management/press-release/PressReleaseTable";
import { AuthProvider, useAuthState } from "../../store/app-context";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function createData(id, title, content, image) {
    return { id, title, content, image };
}

const rows = [];

function PressReleaseManagementPage() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
    const [imgurUrl, setImgurUrl] = React.useState('');
    const [loadActivities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const auth = useAuthState();

    useEffect(() => {
        const urls = [
            API_URL + `/api/activity/edit`
        ]
        try {
            let activities;

            const getActivities = async () => {
                [activities] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setActivities(activities);

                for (var activity of activities) {
                    let id = activity['activity_id'];
                    let title = activity['title'];
                    let content = activity['content'];
                    let image = activity['activity_photo'];

                    rows.push(createData(id, title, content, image));
                }
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }

    }, []);

    if (isLoading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function addPressReleaseHandler(prData, image, attachment) {
        console.log('addPressReleaseHandler...');
        console.log('prData: ' + JSON.stringify(prData));

        const formData = new FormData();
        // formData.append('file', prData.document);
        formData.append('pr', new Blob([JSON.stringify(prData)], { type: "application/json" }));
        formData.append('image', image)
        formData.append('document', attachment);

        // const requestOptions = {
        //     method: 'POST',
        //     body: formData,
        //     headers: {
        //         'Content-Type': undefined,
        //         // 'Accept': 'application/json',
        //         'Authorization': 'Bearer ' + auth.token,
        //     }
        // };

        axios({
            method: "POST",
            url: API_URL + "/api/press-release/add",
            data: formData,

            headers: {
                "Content-Type": "mulitpart/form-data",
                'Authorization': 'Bearer ' + auth.token
            }

        }).then((res) => {
            if (res.status == '201') {
                Swal.fire({
                    title: '完成!',
                    text: '已加入',
                    icon: 'success',
                    confirmButtonText: '關閉'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/management/press-release");
                        navigate(0);
                    }
                }
                )

            } else {
                Swal.fire({
                    title: '失敗!',
                    text: '資料錯誤',
                    icon: 'info',
                    confirmButtonText: '關閉'
                })
            }

        });
    }

    return (
        <RootBox>
            <RootContainer className={classes.root}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="activity tab">
                        <Tab label="新增刊物" {...a11yProps(0)} />
                        <Tab label="現有刊物" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <NewPressReleaseForm
                        addPressReleaseHandler={addPressReleaseHandler} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PressReleaseTable
                        addPressReleaseHandler={addPressReleaseHandler}
                        auth={auth} />
                </TabPanel>
            </RootContainer>
        </RootBox>
    )
}

const PREFIX = "activityMgn";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const RootContainer = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    }
}));

const RootBox = styled(Box)(({ theme }) => ({

    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    paddingTop: '32px',
    color: theme.palette.text.primary,
}));

export default PressReleaseManagementPage;