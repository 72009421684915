import { createContext, useCallback, useReducer, useState, useContext } from 'react';
import { produce } from 'immer';
import { dark } from '@mui/material/styles/createPalette';
import { AuthReducer, initialState } from './reducer';

const AppContext = createContext({
    favorites: [],
    totalFavorites: 0,
    addFavorite: (favoriteMeetup) => { },
    removeFavorite: (meetupId) => { },
    itemIsFavorite: (meetupId) => { },
});

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

export function useAuthState() {
    const context = useContext(AuthStateContext);
    if (context === undefined) {
        throw new Error("useAuthState must be used within a authProvider");
    }

    return context;
}

export function useAuthDispatch() {
    const context = useContext(AuthDispatchContext);
    if (context === undefined) {
        throw new Error("useAuthDispatch  must be used within a authProvider");
    }

    return context;
}

export const AuthProvider = ({ children }) => {
    const [user, dispatch] = useReducer(AuthReducer, initialState);

    return (
        <AuthStateContext.Provider value={user}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}

const isColorMode = (input) => {
    return input === "dark" || input === "light";
};

const isLanguageMode = (input) => {
    return input === "en" || input === "zh-tw";
}

export function AppContextProvider(props) {
    const getInitialState = () => {
        const devicePreferColorScheme =
            localStorage.getItem("colorMode") ||
            (navigator.userAgent === "prerendering" && "light") || // set default color theme in prerendering to "light"
            (window.matchMedia &&
                window.matchMedia("(prefers-color-scheme: light)").matches
                ? "light"
                : "light");

            const devicePreferLanguageScheme =
                localStorage.getItem("LangaugeMode") ||
                (navigator.userAgent === "prerendering" && "zh-tw");

        return {
            colorMode: isColorMode(devicePreferColorScheme)
                ? devicePreferColorScheme
                : "light",
            languageMode: isLanguageMode(devicePreferLanguageScheme)
                ? devicePreferLanguageScheme
                : "zh-tw",
        }
    }

    const [userFavorites, setUserFavorites] = useState([]);
    const [state, setStateRaw] = useState(getInitialState);

    function addFavoriteHandler(favoriteMeetup) {
        setUserFavorites((prevUserFavorites) => {
            return prevUserFavorites.concat(favoriteMeetup);
        });
    }

    function removeFavoriteHandler(meetupId) {
        setUserFavorites((prevUserFavorites) => {
            return prevUserFavorites.filter((meetup) => meetup.id !== meetupId);
        });
    }

    function itemIsFavoriteHandler(meetupId) {
        return userFavorites.some((meetup) => meetup.id === meetupId);
    }

    const toggleColorMode = useCallback(() => {
        console.log('clicked toggle color mode...');
        setStateRaw(
            produce(state => {
                const prevColorMode = state.colorMode;
                const colorMode = prevColorMode === "dark" ? "light" : "dark";
                localStorage.setItem("colorMode", colorMode);
                state.colorMode = colorMode;
            })
        )
    })

    const toggleLanguageMode = useCallback(() => {
        setStateRaw(
            produce(state => {
                const prevLanguageMode = state.languageMode;
                const languageMode = (prevLanguageMode === "en") ? "zh-tw" : "en";
                console.log('prevLanguageMode: ' + prevLanguageMode + ', languageMode: ' + languageMode);
                localStorage.setItem("languageMode", languageMode);
                state.languageMode = languageMode;
            })
        )
    })

    const contextValue = {
        ...state,
        favorites: userFavorites,
        totalFavorites: userFavorites.length,
        addFavorite: addFavoriteHandler,
        removeFavorite: removeFavoriteHandler,
        itemIsFavorite: itemIsFavoriteHandler,
        toggleColorMode: toggleColorMode,
        toggleLanguageMode: toggleLanguageMode,
    };

    return (
        <AppContext.Provider value={contextValue}>
            {props.children}
        </AppContext.Provider >
    );
}

export default AppContext;