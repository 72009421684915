// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend";

// i18n
//    // 使用 i18next-http-backend
//    .use(Backend) 
//    // 將 i18next 傳入 react-i18next 裡面
//    .use(initReactI18next)  
//    // 實例化 initReactI18next
//    .init({
//       backend: {
//          //網頁載入時去下載語言檔的位置
//          loadPath: "/locales/{{lng}}/{{ns}}.json",
//       },
//       // 當目前的語言檔找不到對應的字詞時，會用 fallbackLng (en) 作為預設語言
//       fallbackLng: "zh-tw",
//       // 預設語言
//       lng: "zh-tw",
//       interpolation: {
//          // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
//          escapeValue: false,
//       },
//    });

// export default i18n;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: "zh-tw",
    fallbackLng: 'zh-tw',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;