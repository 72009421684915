import React from "react";
import {
    Container, Box, Divider, Typography, Grid, CardMedia
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "../index.css";
import Breadcrumb from "../components/layout/Breadcrumb";
import { useTranslation } from 'react-i18next';

const breadcrumbData = ["主頁", "關於我們", "會長的話"];

function PresidentSpeechPage(props) {
    const { t, i18n } = useTranslation();

    return (
        <RootBox sx={{ minHeight: 'calc(100vh - 210px)' }}>
            <Root className={classes.root}>
                <Box sx={{ mt: { xs: 8.25, md: 12.5 } }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                {/* Noto Sans HK */}
                <Box sx={{ my: 4 }}>
                    <Typography variant="h4">{"會長的話"}</Typography>
                </Box>
                <Grid container spacing={1} sx={{ mt: { xs: 4 }, mb: 2 }}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                        <CardMedia
                            component="img"
                            sx={{ width: {xs: 200, md: 250 }, height: {xs: 250, md: 300 } }}
                            image="/img/member/president.jpeg"
                            alt="president.jpg"
                        />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h5">
                                {t('president-speech.title')}
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1">
                                {t('president-speech.section1')}
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1">
                                {t('president-speech.section2')}
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1">
                                {t('president-speech.section3')}
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1">
                                {t('president-speech.section4')}
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 2, mb: 2 }}
                            display="flex"
                            justifyContent="right"
                            alignItems="right"
                        >
                            <Typography variant="body1" align="center" style={{ wordWrap: "break-word" }}>
                                陳耀恒會長<br/>2023年10月
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Root>
            
        </RootBox>
    )
}

const PREFIX = "introdution";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.position ? props.position : "center"}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const SubTitle = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.subtitle ? props.subtitle : "center"}
                >
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.about`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 3,
        width: "100%"
        // marginBottom: 10
    },
    [`&.companyBusiness`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 2,
        width: "5%"
        // marginBottom: 10
    },

}));

const ScaleCard = styled(Box)(({ theme }) => ({
    "&:hover": {
        transition: "transform 0.5s ease-in-out",
        transform: "scale3d(1.05, 1.05, 1)"
    },
}));

export default PresidentSpeechPage;