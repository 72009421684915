import React, { useState, useEffect, forwardRef, Fragment, useRef } from "react";
import {
    Container, Box, Divider, Typography, Grid
} from "@mui/material";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CardActionArea } from '@mui/material';
import { styled } from "@mui/material/styles";
import "../index.css";
import Breadcrumb from "../components/layout/Breadcrumb";
import FadeInSection from "../util/FadeInSection";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

// React Router
import { useNavigate } from "react-router-dom";

const breadcrumbData = ["主頁", "關於我們", "商會簡介"];

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


function IntroductionPage(props) {
    const { t, i18n } = useTranslation();
    const [expandedFounderSpeech, setExpandedFounderSpeech] = useState();
    const [expandedTarget, setExpandedTarget] = useState();
    const [expandedPurpose, setExpandedPurpose] = useState();
    const [expandedMission, setExpandedMission] = useState();
    const [expandedJob, setExpandedJob] = useState();

    const handleFounderSpeechExpandClick = () => {
        setExpandedFounderSpeech(!expandedFounderSpeech);
    };

    const handleTargetExpandClick = () => {
        setExpandedTarget(!expandedTarget);
    };

    const handlePurposeExpandClick = () => {
        setExpandedPurpose(!expandedPurpose);
    };

    const handleMissionExpandClick = () => {
        setExpandedMission(!expandedMission);
    };

    const handleJobExpandClick = () => {
        setExpandedJob(!expandedJob);
    };

    return (
        <RootBox>
            <Root className={classes.root}>
                <Box sx={{ mt: {xs: 8.25, md: 12.5} }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Box sx={{ my: 4 }}>
                    <Typography variant="h4">{t('about-us')}</Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant="subtitle1">{t('company.title')}</Typography>
                </Box>
                <CustomDivider className={'companyBusiness'} />
                <Box sx={{ my: 4 }}>
                    <Typography variant="body1">
                        {t('company.introdution1')}
                    </Typography>
                </Box>
                <Box sx={{ my: 4 }}>
                    <Typography variant="body1">
                        {t('company.introdution2')}
                    </Typography>
                </Box>
                <Grid container spacing={1} sx={{ mt: { xs: 4 } }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <FadeInSection>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <ScaleCard
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', maxWidth: '100%' }}
                                >
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="300"
                                            image="/img/founder-msg.jpg"
                                            alt="about1"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                {t('company.founder.title')}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                        <Grid container justifyContent="center">
                                            <ExpandMore
                                                expand={expandedFounderSpeech}
                                                onClick={handleFounderSpeechExpandClick}
                                                aria-expanded={expandedFounderSpeech}
                                                aria-label="show more"
                                            >
                                                <ExpandMoreIcon />
                                            </ExpandMore>
                                        </Grid>
                                        </CardActions>
                                        <Collapse in={expandedFounderSpeech}>
                                            <Typography variant="body1" sx={{ ml: 1, mr: 1, my: 2 }}>{t('company.founder.speech1')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 1, mr: 1, my: 2 }}>{t('company.founder.speech2')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 1, mr: 1, my: 2 }}>{t('company.founder.speech3')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 1, mr: 1, my: 2 }}>{t('company.founder.speech4')}</Typography>
                                            <Typography variant="body1" sx={{ mt: 6, ml: 1, mr: 1, mb: 2 }}>{t('company.founder.name')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 1, mr: 1, my: 2 }}>{t('company.founder.date')}</Typography>
                                        </Collapse>
                                    </CardActionArea>

                                </ScaleCard>
                            </Box>
                        </FadeInSection>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FadeInSection>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <ScaleCard
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', maxWidth: '100%' }}
                                >
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="250"
                                            image="/img/info-target.jpg"
                                            alt="about1"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                {t('company.target.title')}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                        <Grid container justifyContent="center">
                                            <ExpandMore
                                                expand={expandedTarget}
                                                onClick={handleTargetExpandClick}
                                                aria-expanded={expandedTarget}
                                                aria-label="show more"
                                            >
                                                <ExpandMoreIcon />
                                            </ExpandMore>
                                        </Grid>
                                        </CardActions>
                                        <Collapse in={expandedTarget}>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.target.1')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.target.2')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.target.3')}</Typography>
                                        </Collapse>
                                    </CardActionArea>

                                </ScaleCard>
                            </Box>
                        </FadeInSection>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FadeInSection>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <ScaleCard
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', maxWidth: '100%' }}
                                >
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="250"
                                            image="/img/info-purpose.jpg"
                                            alt="about2"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                {t('company.purpose.title')}
                                            </Typography>
                                            {/* <Typography variant="body2" color="text.secondary">
                                                Lizards are a widespread group of squamate reptiles, with over 6,000
                                                species, ranging across all continents except Antarctica
                                            </Typography> */}
                                        </CardContent>
                                        <CardActions>
                                        <Grid container justifyContent="center">
                                            <ExpandMore
                                                expand={expandedPurpose}
                                                onClick={handlePurposeExpandClick}
                                                aria-expanded={expandedPurpose}
                                                aria-label="show more"
                                            >
                                                <ExpandMoreIcon />
                                            </ExpandMore>
                                        </Grid>
                                        </CardActions>
                                        <Collapse in={expandedPurpose}>
                                            <Typography variant="subtitle1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.purpose.subtitle')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.purpose.1')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.purpose.2')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.purpose.3')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.purpose.4')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.purpose.5')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.purpose.6')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.purpose.7')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.purpose.8')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.purpose.9')}</Typography>
                                        </Collapse>
                                    </CardActionArea>

                                </ScaleCard>
                            </Box>
                        </FadeInSection>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h5">
                        {t('company.wish.title')}
                    </Typography>
                </Box>
                <CustomDivider className={'companyBusiness'} />
                <Box sx={{ my: 4 }}>
                    <Typography variant="body1">
                        {t('company.wish.1')}
                    </Typography>
                </Box>
                <Grid container spacing={1} sx={{ mt: { xs: 4 }, mb: 2 }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <FadeInSection>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <ScaleCard
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', maxWidth: '100%' }}
                                >
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="250"
                                            image="/img/info-slogan.jpg"
                                            alt="about4"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                {t('company.mission.title')}
                                            </Typography>
                                            {/* <Typography variant="body2" color="text.secondary">
                                                Lizards are a widespread group of squamate reptiles, with over 6,000
                                                species, ranging across all continents except Antarctica
                                            </Typography> */}
                                        </CardContent>
                                        <CardActions>
                                        <Grid container justifyContent="center">
                                            <ExpandMore
                                                expand={expandedMission}
                                                onClick={handleMissionExpandClick}
                                                aria-expanded={expandedMission}
                                                aria-label="show more"
                                            >
                                                <ExpandMoreIcon />
                                            </ExpandMore>
                                        </Grid>
                                        </CardActions>
                                        <Collapse in={expandedMission}>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.mission.subtitle')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.mission.1')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.mission.2')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.mission.3')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.mission.4')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.mission.5')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.mission.6')}</Typography>
                                        </Collapse>
                                    </CardActionArea>
                                </ScaleCard>
                            </Box>
                        </FadeInSection>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FadeInSection>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <ScaleCard
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', maxWidth: '100%' }}
                                >
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="250"
                                            image="/img/info-responsibility.jpg"
                                            alt="about5"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                {t('company.job.title')}
                                            </Typography>
                                            {/* <Typography variant="body2" color="text.secondary">
                                                Lizards are a widespread group of squamate reptiles, with over 6,000
                                                species, ranging across all continents except Antarctica
                                            </Typography> */}
                                        </CardContent>
                                        <CardActions>
                                        <Grid container justifyContent="center">
                                            <ExpandMore
                                                expand={expandedJob}
                                                onClick={handleJobExpandClick}
                                                aria-expanded={expandedJob}
                                                aria-label="show more"
                                            >
                                                <ExpandMoreIcon />
                                            </ExpandMore>
                                        </Grid>
                                        </CardActions>
                                        <Collapse in={expandedJob}>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.job.1')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.job.2')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.job.3')}</Typography>
                                            <Typography variant="body1" sx={{ ml: 4, mr: 1, my: 2 }}>{t('company.job.4')}</Typography>
                                        </Collapse>
                                    </CardActionArea>
                                </ScaleCard>
                            </Box>
                        </FadeInSection>
                    </Grid>
                </Grid>
            </Root>
        </RootBox>
    )
}

const PREFIX = "introdution";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.position ? props.position : "center"}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const SubTitle = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.subtitle ? props.subtitle : "center"}
                >
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.about`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.main,
        borderBottomWidth: 3,
        width: "100%"
        // marginBottom: 10
    },
    [`&.companyBusiness`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 2,
        width: "5%"
        // marginBottom: 10
    },

}));

const ScaleCard = styled(Box)(({ theme }) => ({
    "&:hover": {
        transition: "transform 0.5s ease-in-out",
        transform: "scale3d(1.05, 1.05, 1)"
    },
}));

export default IntroductionPage;