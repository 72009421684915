import { useState, useEffect, forwardRef, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Editor from "../Editor";
import Typography from "@mui/material/Typography";
import { useAuthState } from "../../../store/app-context";

// Hooks
import { useRef } from "react";

const statusMap = new Map([
    ["現貨", { id: "INSTOCK", name: "現貨" }],
    ["缺貨中", { id: "SOLD", name: "缺貨中" }],
]);

function NewInformationForm(props) {
    const [status, setStatus] = useState();
    const [content, setContent] = useState();
    const [images, setImages] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const titleInputRef = useRef();
    const contentInputRef = useRef();
    const imageInputRef = useRef();
    const auth = useAuthState();

    const handleContent = (content) => {
        setContent(content);
    };

    const handleImages = (image) => {
        console.log(image);
        setImages(images => [...images, image]);
        console.log('list: ' + images);
    }

    function submitHandler(event) {
        event.preventDefault();
        setBtnLoading(true);

        const title = titleInputRef.current.value;
        const content = contentInputRef.current.value;
        let images = [];
        const fileCount = document.getElementsByClassName('input-image')[0].files.length;
        for (var i = 0; i < fileCount; i++) {
            const tmpImage = document.getElementsByClassName('input-image')[0].files[i];
            images.push(tmpImage);
        }
        const createdDate = new Date();
        const lastModifiedDate = new Date();

        const informationData = {
            title: title,
            image: images,
            imageUrl: [],
            content: content,
            createdDate: createdDate,
            lastModifiedDate: lastModifiedDate
        };

        console.log(informationData);

        props.addInformationHandler(informationData);
        setBtnLoading(false);
    }

    const handleChange = (event) => {
        setStatus(event.target.value);
    };


    return (
        <Box sx={{ mt: 1, mb: 5 }} >
                <Box sx={{ my: 1 }}>
                    <label htmlFor="title">標題</label>
                    <TextField required id="title" inputRef={titleInputRef} size="small" sx={{ width: '100%' }} />
                </Box>
                <Box sx={{ my: 1 }}>
                    <label htmlFor="content">內容</label>
                    <TextField required id="content" multiline rows={5} inputRef={contentInputRef} sx={{ width: '100%' }} />
                    {/* <Editor 
                        content={content} 
                        handleContent={handleContent} 
                        handleImages={handleImages}
                        activityImageUpload={props.activityImageUpload}
                        auth={auth}
                    /> */}
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography sx={{ typography: 'subtitle1' }}>封面圖片</Typography>
                    <input type="file" className="input-image" required id="image" ref={imageInputRef} multiple/>
                </Box>
                <Box sx={{ my: 1 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <LoadingButton 
                                variant="contained" 
                                color="warning"
                                loading={btnLoading}
                                loadingPosition="start"  
                                startIcon={<SaveIcon/>}
                                onClick={submitHandler}
                            >
                                <span>加入</span>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
                </Box>
        </Box>
    )
}

export default NewInformationForm;