import { useState, useEffect, forwardRef, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Editor from "../Editor";
import Typography from "@mui/material/Typography";
import { useAuthState } from "../../../store/app-context";

// Hooks
import { useRef } from "react";

function NewCommitteeForm({addCommitteeHandler}) {
    const [committeeName, setCommitteeName] = useState();
    const [btnLoading, setBtnLoading] = useState(false);
    const committeeNameInputRef = useRef();
    const auth = useAuthState();

    function submitHandler(event) {
        event.preventDefault();
        setBtnLoading(true);

        const committeeName = committeeNameInputRef.current.value;
        
        const committeeData = {
            name: committeeName
        };

        console.log(committeeData);

        addCommitteeHandler(committeeData);
        setBtnLoading(false);
    }

    return (
        <Box sx={{ mt: 1, mb: 5 }} >
                <Box sx={{ my: 1 }}>
                    <label htmlFor="title">委員會名稱</label>
                    <TextField required id="title" inputRef={committeeNameInputRef} size="small" sx={{ width: '100%' }} />
                </Box>
                <Box sx={{ my: 1 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <LoadingButton 
                                variant="contained" 
                                color="warning"
                                loading={btnLoading}
                                loadingPosition="start"  
                                startIcon={<SaveIcon/>}
                                onClick={submitHandler}
                            >
                                <span>加入</span>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
                </Box>
        </Box>
    )
}

export default NewCommitteeForm;