import React, { useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { styled, alpha } from "@mui/material/styles";
import { Link, useLocation, useHistory, useNavigate } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "../../store/app-context";
import { logout } from "../../store/action";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import LanguageIcon from '@mui/icons-material/Language';
import InputBase from '@mui/material/InputBase';
import ScrollTop from '../../util/ScrollTop';
import HideOnScroll from '../../util/HideOnScroll';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/ArrowUpward';
import { popoverClasses } from "@mui/material/Popover" 
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import AppContext from "../../store/app-context";

const Header = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
  const [anchorElAbout, setAnchorElAbout] = React.useState(null);
  const [anchorElActivity, setAnchorElActivity] = React.useState(null);
  const [anchorElInfo, setAnchorElInfo] = React.useState(null);
  const [anchorElMember, setAnchorElMember] = React.useState(null);
  const [anchorElSystem, setAnchorElSystem] = React.useState(null);
  const [anchorElManagement, setAnchorElManagement] = React.useState(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openNested, setOpenNested] = React.useState(false);
  const [openNested2, setOpenNested2] = React.useState(false);
  const [openNestedAboutUs, setOpenNestedAboutUs] = React.useState(false);
  const [openNestedActivity, setOpenNestedActivity] = React.useState(false);
  const [openNestedSystem, setOpenNestedSystem] = React.useState(false);

  const { languageMode, toggleLanguageMode } = useContext(AppContext);
  // const  [ language, setLanguage] = useState(localStorage.getItem("languageMode") === 'en' ? 'English' : '中文'); 
  const [language, setLanguage] = React.useState(localStorage.getItem("languageMode")); 

  const auth = useAuthState();
  const dispatch = useAuthDispatch();
  const [loggedIn, setLoggedIn] = React.useState(false);
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const changeLanguage = (event, lng) => {
    console.log('change language -> ' + lng);
    setLanguage(lng);
    i18n.changeLanguage(lng);
  };

  const onKeyDownSearchHandler = (event) => {
    if(event.keyCode == 13){
        let keyword = event.target.value
        navigate('/search/' + keyword);
        navigate(0);
    }
  }

  const handleNestedClick = () => {
    setOpenNested(!openNested);
  };

  const handleNestedClick2 = () => {
    setOpenNested2(!openNested2);
  };

  const handleNestedClickAboutUs = () => {
    setOpenNestedAboutUs(!openNestedAboutUs);
  };

  const handleNestedClickActivity = () => {
    setOpenNestedActivity(!openNestedActivity);
  };

  const handleNestedClickSystem = () => {
    setOpenNestedSystem(!openNestedSystem);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenLanguageMenu = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };
  const handleOpenAboutMenu = (event) => {
    setAnchorElAbout(event.currentTarget);
  };
  const handleOpenActivityMenu = (event) => {
    setAnchorElActivity(event.currentTarget)
  }
  const handleOpenInfoMenu = (event) => {
    setAnchorElInfo(event.currentTarget)
  }
  const handleOpenMemberMenu = (event) => {
    setAnchorElMember(event.currentTarget)
  }
  const handleOpenSystemMenu = (event) => {
    setAnchorElSystem(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenManagementMenu = (event) => {
    setAnchorElManagement(event.currentTarget)
  }

  const handleCloseManagementMenu = () => {
    setAnchorElManagement(null);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorElLanguage(null);
  };

  const handleCloseAboutMenu = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setAnchorElAbout(null);
      }
    }, 50);
  };

  const handleCloseActivityMenu = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setAnchorElActivity(null);
      }
    }, 50);
  };

  const handleCloseInfoMenu = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setAnchorElInfo(null);
      }
    }, 50);
  };

  const handleCloseMemberMenu = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setAnchorElMember(null);
      }
    }, 50);
  };

  const handleCloseSystemMenu = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        setAnchorElSystem(null);
      }
    }, 50);
  };

  let currentlyHovering = false;
  function handleHover() {
    currentlyHovering = true;
  }

  const handleLogoutBtnClick = () => {
    logout(dispatch);
    window.location = '/';
  };

  const toCategoryPage = (categoryId) => {
    window.location = '/collection/' + categoryId;
  }

  const handleChangeLanguage = (clickedLng) => {
    if (languageMode === 'en' && clickedLng !== 'en') {
      // setLanguage('中文');
      changeLanguage(null, "zh-tw");
      toggleLanguageMode("zh-tw");
    } else if (languageMode === 'zh-tw' && clickedLng !== 'zh-tw') {
      // setLanguage('English');
      changeLanguage(null, "en");
      toggleLanguageMode("en");
    } 
    
    handleCloseLanguageMenu();
  }

  React.useEffect(() => {
    if (auth.userDetails || auth.user) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const DrawerList = () => {
    return (
      <Box
        sx={{width: '250', height: '100%', bgcolor: '#c9bea8'}}
        // role="presentation"
        // onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      >
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: '#c9bea8' ,color: 'black'}}
          component="nav"
        >
          <ListItem disablePadding>
            <ListItemButton>
              <CloseIcon onClick={toggleDrawer}/>
            </ListItemButton>
          </ListItem>
          <ListItemButton onClick={handleNestedClickAboutUs}>
            <ListItemText primary={t('about-us')} />
            {openNestedAboutUs ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <ListItem disablePadding>
            <Collapse in={openNestedAboutUs} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} onClick={() => {
                    navigate(`/about/introduction`);
                    toggleDrawer();
                  }}>
                  <ListItemText primary="簡介" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} onClick={() => {
                    navigate(`/about/speech`);
                    toggleDrawer();
                  }}>
                  <ListItemText primary="會長的話" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} onClick={() => {
                    navigate(`/about/organization`);
                    toggleDrawer();
                  }}>
                  <ListItemText primary="組織架構" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} onClick={() => {
                    navigate(`/about/committee`)
                    toggleDrawer();
                  }}>
                  <ListItemText primary="轄下委員會" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} onClick={() => {
                    navigate(`/about/consultant`)
                    toggleDrawer();
                  }}>
                  <ListItemText primary="名譽總監、名譽顧問及名譽法律顧問" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
          <ListItemButton onClick={handleNestedClickActivity}>
            <ListItemText primary={t('activity')} />
            {openNestedActivity ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <ListItem disablePadding>
            <Collapse in={openNestedActivity} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} 
                  onClick={() => {
                    navigate(`/activity/latest`);
                    toggleDrawer();
                  }}
                >
                  <ListItemText primary="最新活動" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} onClick={() => {
                    navigate(`/activity/history`);
                    toggleDrawer();
                  }}>
                  <ListItemText primary="活動重温" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
          <ListItemButton onClick={handleNestedClick}>
            <ListItemText primary={t('information')} />
            {openNested ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <ListItem disablePadding>
            <Collapse in={openNested} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }} onClick={() => {
                    navigate('/media/latest-information');
                    toggleDrawer();
                  }}>
                  <ListItemText primary="最新資訊" />
                </ListItemButton>
                <ListItemButton sx={{ pl: 4 }} onClick={() => {
                    navigate('/media/press-releases');
                    toggleDrawer();
                  }}>
                  <ListItemText primary="刊物" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={handleNestedClick2}>
              <ListItemText primary={t('menu-member')} />
              {openNested2 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <Collapse in={openNested2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate(`/member/benefits`);
                    toggleDrawer();
                  }}
                >
                  <ListItemText primary="會員福利" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate(`/member/member-list`);
                    toggleDrawer();
                  }}
                >
                  <ListItemText primary="會員名冊" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate(`/member/becomes-member`);
                    toggleDrawer();
                  }}
                >
                  <ListItemText primary="成為會員" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={handleNestedClickSystem}>
              <ListItemText primary={t('menu-system')} />
              {openNestedSystem ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <Collapse in={openNestedSystem} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate(`/system/rules`);
                    toggleDrawer();
                  }}
                >
                  <ListItemText primary="會規" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate(`/system/integrity-and-ethics`);
                    toggleDrawer();
                  }}
                >
                  <ListItemText primary="誠信及操守" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/contact-us`);
                toggleDrawer();
              }}
            >
              <ListItemText primary={t('contact-us.menu')} />
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
              <ToggleButtonGroup
                color="primary"
                size="small"
                value={language}
                exclusive
                onChange={changeLanguage}
                aria-label="language"
                sx={{ ml: 2 }}
              >
                <ToggleButton value="zh-tw">中</ToggleButton>
                <ToggleButton value="en">Eng</ToggleButton>
              </ToggleButtonGroup>
          </ListItem> */}
        </List>
        {/* <List 
        sx={{ width: '100%', maxWidth: 360, bgcolor: '#f3f3f3' }}
          component="nav">
          <ListItem>
            <Grid item xs={12} sm={12} md={12}
              container
              spacing={0}
              direction="column"
              justifyContent="center">
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="搜尋..."
                  inputProps={{ 'aria-label': 'search' }}
                  onKeyDown={onKeyDownSearchHandler}
                />
              </Search>
            </Grid>
          </ListItem>
        </List> */}
      </Box>
    )
  }


  return (
    <React.Fragment>
    <HideOnScroll {...props}>
    <Root
      value={location.pathname.replace(/(.*)\/[0-9]*?$/, "$1")}
      // showLabels={true}
      classes={{ root: classes.root }}
    >
      <Box
        component="nav"
        sx={{ width: { sm: 240 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          // container={container}
          variant="temporary"
          anchor="left"
          open={openDrawer}
          onClose={toggleDrawer}
          // onOpen={toggleDrawer(true)}
          // ModalProps={{
          //   keepMounted: true, // Better open performance on mobile.
          // }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          <DrawerList />
        </Drawer>
      </Box>
      {isSmall ? 
        <Toolbar disableGutters>
          {/* For mobile */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, backgroundColor: '#c9bea8' }}>
            <IconButton
              size="large"
              aria-label="language"
              aria-controls="menu-language"
              aria-haspopup="true"
              // onClick={handleOpenNavMenu}
              onClick={toggleDrawer}
              // color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-language"
              anchorEl={anchorElLanguage}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElLanguage)}
              onClose={handleCloseLanguageMenu}
              sx={{
                display: { xs: 'block', md: 'block' },
              }}
              disableScrollLock={true}
            >
              <MenuItem onClick={() => {handleChangeLanguage('zh-tw')}}>
                <Typography textAlign="center">中文</Typography>
              </MenuItem>
              <MenuItem onClick={() => {handleChangeLanguage('en')}}>
                <Typography textAlign="center">English</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            onClick={() => {
              navigate('/')
            }}
            sx={{ mr: 1, display: { xs: 'flex', md: 'flex' }, py: 2 }} // display: { xs: 'flex', md: 'none' }
          >
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <img height='120rem' width="100%" src="../img/hkmeca-logo.svg"/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <img height='85rem' width="100%" src="../img/hkmeca-logo.svg"/>
            </Box>
            
          </Typography>
        </Toolbar>
        :
        <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          <Typography
            variant="h6"
            noWrap
            component="div"
            onClick={() => {
              navigate('/')
            }}
            sx={{ mr: 1, display: { xs: 'flex', md: 'flex' }, py: 2 }} // display: { xs: 'flex', md: 'none' }
          >
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <img height='120rem' width="100%" src="../img/hkmeca-logo.svg"/>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <img height='75rem' width="100%" src="../img/hkmeca-logo.svg"/>
            </Box>
            
          </Typography>

          {/* For desktop */}
          <Box justifyContent="flex-end" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
              onMouseOver={handleOpenAboutMenu}
              onMouseLeave={handleCloseAboutMenu}
              sx={{ my: 1, color: 'black', display: 'block' }}
            >
              <Typography sx={{ fontSize: '1.25rem' }}>{t('about-us')}</Typography>
            </Button>
            <Button
              onMouseOver={handleOpenActivityMenu}
              onMouseLeave={handleCloseActivityMenu}
              sx={{ my: 1, color: 'black', display: 'block' }}
            >
              <Typography sx={{ fontSize: '1.25rem' }}>{t('activity')}</Typography>
            </Button>
            <Button
              onClick={() => {

              }}
              onMouseOver={handleOpenInfoMenu}
              onMouseLeave={handleCloseInfoMenu}
              sx={{ my: 1, color: 'black', display: 'block' }}
            >
              <Typography sx={{ fontSize: '1.25rem' }}>{t('information')}</Typography>
            </Button>
            <Button
              onClick={() => {

              }}
              onMouseOver={handleOpenMemberMenu}
              onMouseLeave={handleCloseMemberMenu}
              sx={{ my: 1, color: 'black', display: 'block'}}
            >
              <Typography sx={{ fontSize: '1.25rem' }}>{t('menu-member')}</Typography>
            </Button>
            <Button
              onClick={() => {

              }}
              onMouseOver={handleOpenSystemMenu}
              onMouseLeave={handleCloseSystemMenu}
              sx={{ my: 1, color: 'black', display: 'block'}}
            >
              <Typography sx={{ fontSize: '1.25rem' }}>{t('menu-system')}</Typography>
            </Button>
            <Button
              onClick={() => navigate(`/contact-us`)}
              // onMouseOver={handleOpenTestMenu}
              sx={{ my: 1, color: 'black', display: 'block' }}
            >
              <Typography sx={{ fontSize: '1.25rem' }}>{t('contact-us.menu')}</Typography>
            </Button>
            {loggedIn ? (
              <>
                <Button
                  onClick={handleLogoutBtnClick}
                  sx={{ my: 1, color: 'black', display: 'block' }}
                >
                  <Typography sx={{ fontSize: '1.25rem' }}>登出</Typography>
                </Button>
              </>
            )
              : <></>
            }
          </Box>
          <Menu
              id="menu-about"
              anchorEl={anchorElAbout}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElAbout)}
              onClose={handleCloseAboutMenu}
              disableScrollLock={true}
              MenuListProps={{ onMouseEnter: handleHover,
                onMouseLeave: handleCloseAboutMenu,
                style: { pointerEvents: "auto" } }}
                sx={{ right: 32, left: 16, pointerEvents: "none", mt: 1 }}
                PaperProps={{
                  style: {
                       maxWidth: '100vw',
                       width: '100vw',
                       backgroundColor: "#fafaf4"
                     }
                   }}
            >
              <Container>
              <Stack direction="row" justifyContent="flex-end" sx={{ mb: 5, mr: 5 }}>
                  <MenuItem onClick={() => {
                    navigate(`/about/introduction`)
                  }}>
                    <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>簡介</Typography></ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    navigate(`/about/speech`)
                  }}>
                    <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>會長的話</Typography></ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    navigate(`/about/organization`)
                  }}>
                    <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>組織架構</Typography></ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    navigate(`/about/committee`)
                  }}>
                    <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>轄下委員會</Typography></ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    navigate(`/about/consultant`)
                  }}>
                    <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>名譽總監、名譽顧問及名譽法律顧問</Typography></ListItemText>
                  </MenuItem>
              </Stack>
              </Container>
          </Menu>
          <Menu
              id="menu-activity"
              anchorEl={anchorElActivity}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElActivity)}
              onClose={handleCloseActivityMenu}
              disableScrollLock={true}
              MenuListProps={{ onMouseEnter: handleHover,
                onMouseLeave: handleCloseActivityMenu,
                style: { pointerEvents: "auto" } }}
                sx={{ right: 32, left: 16, pointerEvents: "none", mt: 1 }}
                PaperProps={{
                  style: {
                       maxWidth: '100vw',
                       width: '100vw',
                       backgroundColor: "#fafaf4"
                     }
                   }}
            >
              <Container>
              <Stack direction="row" justifyContent="flex-end" sx={{ mb: 5, mr: 5 }}>
                  <MenuItem onClick={() => {
                    navigate(`/activity/latest`)
                  }}>
                    <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>最新活動</Typography></ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    navigate(`/activity/history`)
                  }}>
                    <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>活動重溫</Typography></ListItemText>
                  </MenuItem>
              </Stack>
              </Container>
          </Menu>
          <Menu
            id="menu-info"
            anchorEl={anchorElInfo}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElInfo)}
            onClose={handleCloseInfoMenu}
            disableScrollLock={true}
            MenuListProps={{ onMouseEnter: handleHover,
              onMouseLeave: handleCloseInfoMenu,
              style: { pointerEvents: "auto" } }}
            sx={{ right: 32, left: 16, pointerEvents: "none", mt: 1 }}
            PaperProps={{
              style: {
                   maxWidth: '100vw',
                   width: '100vw',
                   backgroundColor: "#fafaf4"
                 }
               }}
          >
          <Container>
            <Stack direction="row" justifyContent="flex-end" sx={{ mb: 5, mr: 5 }}>
                <MenuItem onClick={() => {
                  navigate('/media/latest-information')
                }}>
                  <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>最新資訊</Typography></ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                  navigate('/media/press-releases')
                }}>
                  <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>刊物</Typography></ListItemText>
                </MenuItem>
            </Stack>
          </Container>
          </Menu>
          <Menu
            id="menu-member"
            anchorEl={anchorElMember}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElMember)}
            onClose={handleCloseMemberMenu}
            disableScrollLock={true}
            MenuListProps={{ onMouseEnter: handleHover,
              onMouseLeave: handleCloseMemberMenu,
              style: { pointerEvents: "auto" } }}
            sx={{ right: 32, left: 16, pointerEvents: "none", mt: 1 }}
            PaperProps={{
              style: {
                   maxWidth: '100vw',
                   width: '100vw',
                   backgroundColor: "#fafaf4"
                 }
               }}
          >
            <Container>
            <Stack direction="row" justifyContent="flex-end" sx={{ mb: 5, mr: 5 }}>
                <MenuItem onClick={() => {
                  navigate(`/member/benefits`)
                }}>
                  <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>會員福利</Typography></ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                  navigate(`/member/member-list`)
                }}>
                  <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>會員名冊</Typography></ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                  navigate(`/member/becomes-member`)
                }}>
                  <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>成為會員</Typography></ListItemText>
                </MenuItem>
            </Stack>
            </Container>
          </Menu>
          <Menu
            id="menu-member"
            anchorEl={anchorElSystem}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElSystem)}
            onClose={handleCloseSystemMenu}
            disableScrollLock={true}
            MenuListProps={{ onMouseEnter: handleHover,
              onMouseLeave: handleCloseSystemMenu,
              style: { pointerEvents: "auto" } }}
            sx={{ right: 32, left: 16, pointerEvents: "none", mt: 1 }}
            PaperProps={{
              style: {
                   maxWidth: '100vw',
                   width: '100vw',
                   backgroundColor: "#fafaf4"
                 }
               }}
          >
            <Container>
            <Stack direction="row" justifyContent="flex-end" sx={{ mb: 5, mr: 5 }}>
                <MenuItem onClick={() => {
                  navigate(`/system/rules`)
                }}>
                  <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>會規</Typography></ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                  navigate(`/system/integrity-and-ethics`)
                }}>
                  <ListItemText><Typography sx={{ fontSize: '1.25rem' }}>誠信及操守</Typography></ListItemText>
                </MenuItem>
            </Stack>
          </Container>
          </Menu>
          
          {/* <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <Tooltip>
              <IconButton onClick={handleOpenLanguageMenu} sx={{ p: 0 }}>
                <LanguageIcon />
              </IconButton>
            </Tooltip>
          </Box> */}
        </Toolbar>
      </Container>
      }
      
    </Root>
    </HideOnScroll>
    <Toolbar id="toolbar"/>
    <ScrollTop {...props}>
        <Fab color="#7a5d26" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
};

const PREFIX = "header";

const classes = {
  root: `${PREFIX}-root`,
  selected: `${PREFIX}-selected`,
};

const Root = styled(AppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    background:
      theme.palette.mode === "dark"
        ? theme.palette.background.default
        : '#c9bea8'
    // position: "sticky",
    // width: "100%",
    // bottom: "0",
    // height: "initial",
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.light, 0.15),
  '&:hover': {
      backgroundColor: alpha(theme.palette.primary.light, 0.25),
  },
  marginLeft: 0,
  marginRight: '1.5em',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      // width: '100%',
      width: '12ch',
          '&:focus': {
              width: '14ch',
          },
      
  },
}));

export default Header;
