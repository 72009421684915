import React, { useState, useEffect, forwardRef } from "react";
import {
    Container, Slide, Box, Divider, Typography, Grid, Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "../index.css";
import Breadcrumb from "../components/layout/Breadcrumb";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';

// React Router
import { useNavigate } from "react-router-dom";

const breadcrumbData = ["主頁", "聯絡我們"];

function ContactUsPage(props) {
    const { t, i18n } = useTranslation();

    // change title
    useEffect(() => {
        document.title = '聯絡我們';
    });

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.action.hover,
            //color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        [`&.grey`]: {
            backgroundColor: theme.palette.action.hover,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    return (
        <RootBox>
            <Root className={classes.root}>
                <Box sx={{ mt: {xs: 8.25, md: 12.5} }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Grid container spacing={2} sx={{ mb: { xs: 2 }, p: { md: 5 } }} >
                    <Grid item xs={12} sm={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Title title={t('contact-us.menu')} position={"left"} />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ pb: { xs: 2, md: 5 } }}
                        >
                            <CustomDivider className={'about'} />
                        </Box>
                        <SubTitle subtitle={t('contact-us.info.title')} position={"left"} />
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="contact us table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell align="left"><Typography variant="body1">聯絡</Typography></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell className="grey" rowSpan={3} component="th" scope="row">
                                            <Typography variant="body1">{t('contact-us.info.contact')}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left"><Typography variant="body1">{t('contact-us.info.tel-label')}: {t('contact-us.info.tel')}</Typography></StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell align="left"><Typography variant="body1">{t('contact-us.info.enquiry-form')}</Typography>
                                            <Link underline="hover" key="1" color="inherit" href="/#/enquiry">
                                                <Typography variant="body1">{t('contact-us.info.enquiry-form-enter')} <NavigateNextIcon /></Typography>
                                            </Link>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell align="left"><Typography variant="body1">{t('contact-us.info.email-label')}: {t('contact-us.info.email')}</Typography></StyledTableCell>
                                    </StyledTableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ pt: { xs: 2, md: 6 }, pb: { xs: 2, md: 2 } }}
                        >
                            <CustomDivider className={'about'} />
                        </Box>
                        <SubTitle subtitle={t('contact-us.office.center')} position={"left"} />
                        <Stack direction="row" sx={{ mb: 2 }}>
                            <FmdGoodIcon />
                            <Box>
                                <Typography variant="body1">{t('contact-us.office.address')}</Typography>
                                <Link underline="hover" key="1" color="inherit" href="https://goo.gl/maps/hk9sLmEmPuaQVnJE6" target="_blank" rel="noopener">
                                    <Typography variant="body1">{t('contact-us.office.location-label')} <NavigateNextIcon /></Typography>
                                </Link>
                                <Box>
                                    <img height='100%' width="100%" src="../img/office-location.png"/>
                                </Box>
                            </Box>
                        </Stack>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="contact us table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left"><Typography variant="body1">{t('contact-us.office.office-hour.title')}</Typography></StyledTableCell>
                                        <StyledTableCell align="left"><Typography variant="body1">{t('contact-us.office.tel-label')}</Typography></StyledTableCell>
                                        <StyledTableCell align="left"><Typography variant="body1">{t('contact-us.office.fax-label')}</Typography></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell className="grey" rowSpan={3} component="th" scope="row">
                                        <Typography variant="body1">{t('contact-us.office.office-hour.1')}<br />
                                            {t('contact-us.office.office-hour.2')}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left"><Typography variant="body1">{t('contact-us.office.tel')}</Typography></StyledTableCell>
                                        <StyledTableCell align="left"><Typography variant="body1">{t('contact-us.office.fax')}</Typography></StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Root>
        </RootBox>
    )

}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PREFIX = "contact-us";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body1,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.position ? props.position : "center"}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const SubTitle = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.subtitle ? props.subtitle : "center"}
                >
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.about`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 3,
        width: "100%"
        // marginBottom: 10
    },

}));

export default ContactUsPage;