import { useState, useEffect, forwardRef, Fragment } from "react";
// React Router
import { useNavigate, useLocation, useParams } from "react-router-dom";
import MapIcon from '@mui/icons-material/Map';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import CampaignIcon from '@mui/icons-material/Campaign';
import { yellow } from '@mui/material/colors'
import { styled } from "@mui/material/styles";
import { API_URL } from '../environment';
import Breadcrumb from "../components/layout/Breadcrumb";
import { toDateString } from "../util/TimeUtil";

const breadcrumbData = ["主頁", "活動", "最新活動"];

function createActivityData(id, title, content, images, createdDate, lastModifiedDate) {
    return { id, title, content, images, createdDate, lastModifiedDate };
}

function ActivityNewPage(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [result, setResult] = useState([]);
    const [activities, setActivities] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { type } = useParams();
    let url = '';
    let title = '';
    let data;

    const toContentPage = (id) => {
        navigate('/activity/' + id);
        //navigate(0); // force to reload page
    }

    url = API_URL + `/api/activity?day=90`;

    useEffect(() => {
        const urls = [url]
        try {
            const getActivities = async () => {
                [data] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                        .catch((error) => {
                            setIsLoading(false);
                            setActivities([]);
                            console.log(error);
                        })
                    )
                );
                setIsLoading(false);
                var activityData = [];

                for (var a of data) {
                    let id = a['activityId'];
                    let title = a['title'];
                    let content = a['content'];
                    let createdDate = a['createdDate'];
                    let lastModifiedDate = a['lastModifiedDate'];

                    let images = [];
                    for (var i of a['activityImages']) {
                        images.push(i['imageUrl']);
                    }
                    activityData.push(createActivityData(id, title, content, images, createdDate, lastModifiedDate));
                }
                setActivities(activityData);
            }
            getActivities();
            
        } catch (error) {
            console.log(error);
        }

    }, []);


    if (isLoading) {
        return (
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <RootBox sx={{ minHeight: 'calc(100vh - 210px)' }}>
            <Root className={classes.root}>
                <Box sx={{ mt: {xs: 8.25, md: 12.5} }}>
                        <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Box sx={{ my: 4 }}>
                    <Typography variant="h4">最新活動</Typography>
                    <Typography variant="subtitle2">最近三個月活動</Typography>
                </Box>
                <Box>{/*sx={{ backgroundColor: "#f1f1f1"}}*/}
                    <ActivityGrid activityList={activities} toContentPage={toContentPage} sx={{ flexGrow: 1 }} />
                </Box>
            </Root>
        </RootBox>
    )
}

function ActivityGrid({activityList, toContentPage}) {
    if (activityList.length > 0) {
        return (
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                {activityList.map((item) => (
                    <Grid item xs={12} sm={2} md={3} key={item.id} 
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Card sx={{ width: '100%' }}>
                            <Link href="" onClick={(e) => { e.preventDefault(); toContentPage(item.id) }} color="inherit">
                            <CardMedia
                                component="img"
                                alt={item.images[0]}
                                height="140"
                                image={item.images[0]}
                                onClick={(e) => { e.preventDefault(); toContentPage(item.id) }}
                            />
                            </Link>
                            <CustomDivider className={'cardContent'} />
                            <CardContent sx={{ height: 150 }}>
                                {/* <Link href="" onClick={(e) => { e.preventDefault(); props.toContentPage(item.id) }} underline="hover" color="#985b10">
                                    <Typography align="left" gutterBottom variant="subtitle1" component="div">
                                        {item.category === "promotion" ? "泊車優惠" : (item.category === "parkbin" ? "十八區玩樂" : "")}
                                    </Typography>
                                </Link> */}
                                <Link href="" onClick={(e) => { e.preventDefault(); toContentPage(item.id) }} underline="hover" color="inherit">
                                    <Typography 
                                        sx={{
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                        }}
                                        align="left" variant="h5" component="div">
                                        {item.title}
                                    </Typography>
                                </Link>
                                <Typography variant="body1" color="text.secondary">
                                    {toDateString(new Date(item.createdDate))}
                                </Typography>
                            </CardContent>
                            {/* <CardActions>
                                <Button size="small">Share</Button>
                                <Button size="small">Learn More</Button>
                            </CardActions> */}
                        </Card>
                    </Grid>
                ))}
            </Grid>
        )
    } else {
        return (
            <Box>
                <Typography variant="h6" color="text.secondary">
                    未有相關資訊
                </Typography>
            </Box>
        )
    }
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.categoryResult`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.main,
        borderBottomWidth: 6,
        marginBottom: 20
    },
    // [`&.cardContent`]: {
    //     background:
    //         theme.palette.mode === "dark"
    //             ? yellow['A400']
    //             : yellow['A400'],
    //     borderBottomWidth: 5
    // },
    [`&.cardContent`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#c9bea8',
        borderBottomWidth: 5,
        width: "100%"
        // marginBottom: 10
    },

}));

const PREFIX = "catgoryResult";

const classes = {
    root: `${PREFIX}-root`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        height: '100%',
        backgroundColor: theme.palette.mode === 'dark'
            ? '#1A2027'
            : '#fff',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '90vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : '#FFFFFF',
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

export default ActivityNewPage;