import React, { useState, useEffect, forwardRef, Fragment } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from "@mui/material/styles";
import "../../index.css";
// import { Key } from '../../imgurKey';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from 'axios';

import { API_URL } from '../../environment';

// React Router
import { useNavigate } from "react-router-dom";

// Components
import NewCommitteeForm from '../../components/management/committee/NewCommitteeForm';
import NewCommitteeMemberForm from '../../components/management/committee/NewCommitteeMemberForm';
import CommitteeTable from ".././../components/management/committee/CommitteeTable";
import CommitteeMemberTable from ".././../components/management/committee/CommitteeMemberTable";
import { AuthProvider, useAuthState } from "../../store/app-context";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function createData(id, title, content, image) {
    return { id, title, content, image };
}

const rows = [];

function CommitteeManagementPage() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
    const [imgurUrl, setImgurUrl] = React.useState('');
    const [committees, setCommittees] = useState([]);
    const [committeeMembers, setCommitteeMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const auth = useAuthState();
    console.log('auth: ' + JSON.stringify(auth));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        try {
            let data;
            let data2;
            const urls = [API_URL + `/api/committee`, API_URL + `/api/committee/members`];

            const getCommittees = async () => {
                [data, data2] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                        .catch((error) => {
                            setIsLoading(false);
                            console.log(error);
                        })
                    )
                );
                setIsLoading(false);
                var committeeData = [];
                var memberData = [];

                for (var c of data) {
                    let id = c['committeeId'];
                    let name = c['name'];

                    let members = [];
                    for (var i of c['members']) {
                        members.push(i['name']);
                    }
                    committeeData.push({ id, name, members });
                }
                setCommittees(committeeData);

                for (var m of data2) {
                    let memberId = m['memberId'];
                    let memberName = m['memberName'];
                    let committeeId = m['committeeId'];
                    let committeeName = m['committeeName'];

                    memberData.push({ memberId, memberName, committeeId, committeeName });
                }
                setCommitteeMembers(memberData);
            }
            getCommittees();
            
        } catch (error) {
            console.log(error);
        }

    }, []);

    if (isLoading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function addCommitteeHandler(committeeData) {
        console.log('addCommitteeHandler...');
        console.log('committeeData: ' + JSON.stringify(committeeData));

            const requestOptions = {
                method: 'POST',
                body: JSON.stringify(committeeData),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + auth.token,
                }
            };

            fetch(
                API_URL + "/api/committee/add",
                requestOptions
            ).then((res) => {
                if (res.status == '201') {
                    Swal.fire({
                        title: '完成!',
                        text: '已加入',
                        icon: 'success',
                        confirmButtonText: '關閉'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/management/committee");
                            navigate(0);
                        }
                    }
                    )

                } else {
                    Swal.fire({
                        title: '失敗!',
                        text: '資料錯誤',
                        icon: 'info',
                        confirmButtonText: '關閉'
                    })
                }
        })

    }

    function addMemberHandler(memberData) {
        console.log('addMemberHandler...');
        console.log('memberData: ' + JSON.stringify(memberData));

            const requestOptions = {
                method: 'POST',
                body: JSON.stringify(memberData),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + auth.token,
                }
            };

            fetch(
                API_URL + "/api/committee/member/add",
                requestOptions
            ).then((res) => {
                if (res.status == '201') {
                    Swal.fire({
                        title: '完成!',
                        text: '已加入',
                        icon: 'success',
                        confirmButtonText: '關閉'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/management/committee");
                            navigate(0);
                        }
                    }
                    )

                } else {
                    Swal.fire({
                        title: '失敗!',
                        text: '資料錯誤',
                        icon: 'info',
                        confirmButtonText: '關閉'
                    })
                }
        })

    }

    const removeCommitteeHandler = (id) => {
        setIsLoading(true);
        axios({
            method: "POST",
            url: API_URL + "/api/committee/remove/" + id,
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + auth.token
            }

        }).then((res) => {
            setIsLoading(false);
            if (res.status == '201') {
                Swal.fire({
                    title: '完成!',
                    text: '已刪除',
                    icon: 'success',
                    confirmButtonText: '關閉'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/management/committee");
                        navigate(0);
                    }
                }
                )

            } else {
                Swal.fire({
                    title: '失敗!',
                    text: '錯誤',
                    icon: 'info',
                    confirmButtonText: '關閉'
                })
            }

        })
        .catch((error) => {
            Swal.fire({
                title: '失敗!',
                text: '錯誤',
                icon: 'info',
                confirmButtonText: '關閉'
            })
            setIsLoading(false);
            console.log(error);
        });
    }

    const removeMemberHandler = (id) => {
        setIsLoading(true);
        axios({
            method: "POST",
            url: API_URL + "/api/committee/member/remove/" + id,
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + auth.token
            }

        }).then((res) => {
            setIsLoading(false);
            if (res.status == '201') {
                Swal.fire({
                    title: '完成!',
                    text: '已刪除',
                    icon: 'success',
                    confirmButtonText: '關閉'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/management/committee");
                        navigate(0);
                    }
                }
                )

            } else {
                Swal.fire({
                    title: '失敗!',
                    text: '錯誤',
                    icon: 'info',
                    confirmButtonText: '關閉'
                })
            }

        })
        .catch((error) => {
            Swal.fire({
                title: '失敗!',
                text: '錯誤',
                icon: 'info',
                confirmButtonText: '關閉'
            })
            setIsLoading(false);
            console.log(error);
        });
    }

    return (
        <RootBox>
            <RootContainer className={classes.root}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="activity tab">
                        <Tab label="委員會" {...a11yProps(0)} />
                        <Tab label="委員會委員" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <NewCommitteeForm
                        addCommitteeHandler={addCommitteeHandler}
                        auth={auth} />
                    <CommitteeTable
                        addCommitteeHandler={addCommitteeHandler}
                        removeCommitteeHandler={removeCommitteeHandler}
                        committees={committees}
                        auth={auth} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <NewCommitteeMemberForm
                        committees={committees}
                        addMemberHandler={addMemberHandler}
                        auth={auth} />
                    <CommitteeMemberTable
                        committeeMembers={committeeMembers}
                        removeMemberHandler={removeMemberHandler}
                        auth={auth} />
                </TabPanel>
            </RootContainer>
        </RootBox>
    )
}

const PREFIX = "activityMgn";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const RootContainer = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    }
}));

const RootBox = styled(Box)(({ theme }) => ({

    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    paddingTop: '32px',
    color: theme.palette.text.primary,
}));

export default CommitteeManagementPage;