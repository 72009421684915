import React, { useState, useEffect, forwardRef, Fragment } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from "@mui/material/styles";
import "../../index.css";
// import { Key } from '../../imgurKey';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from 'axios';
import { API_URL } from '../../environment';

// React Router
import { useNavigate } from "react-router-dom";

// Components
import NewInformationForm from '../../components/management/information/NewInformationForm';
import InformationTable from ".././../components/management/information/InformationTable";
import { AuthProvider, useAuthState } from "../../store/app-context";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function createData(id, title, content, image) {
    return { id, title, content, image };
}

const rows = [];

function InformationManagementPage() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
    const [imgurUrl, setImgurUrl] = React.useState('');
    const [loadActivities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const auth = useAuthState();
    console.log('auth: ' + JSON.stringify(auth));

    useEffect(() => {
        const urls = [
            API_URL + `/api/activity/edit`
        ]
        try {
            let activities;

            const getActivities = async () => {
                [activities] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setActivities(activities);

                for (var activity of activities) {
                    let id = activity['activity_id'];
                    let title = activity['title'];
                    let content = activity['content'];
                    let image = activity['activity_photo'];

                    rows.push(createData(id, title, content, image));
                }
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }

    }, []);

    if (isLoading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Image to FTP directory
    const informationImageUpload = async (informationData) => {
        let imageUrl = [];

        try {
            for (var i = 0; i < informationData.image.length; i++) {
                const d = new FormData();
                d.append('file', informationData.image[i]);


                await fetch(
                    API_URL + "/api/file/ftp-upload",
                    {
                        method: 'POST',
                        body: d,
                        headers: {
                            'Authorization': 'Bearer ' + auth.token,
                        },
                    }
                )
                    .then(res => res.json())
                    .then((result) => {
                        console.log('result.data.link: ' + result.fileDownloadUri);
                        imageUrl.push(result.fileDownloadUri);
                    })
            }
            return imageUrl;
        } catch (error) {
            console.error(error);
            return false;
        }

    }

    const removeImageHandler = (imageIds) => {
        console.log("number of image to be removed: " + imageIds.length);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + auth.token,
            }
        };
        // TODO: remove from api server
        fetch(
            API_URL + "/api/information/removeImage?imageId=" + imageIds,
            requestOptions
        ).then((res) => {
            if (res.status == '201') {
                console.log("remove image successfully!")
            } else {
                console.log("remove image failed!");
            }

        });
    }

    function addInformationHandler(informationData) {
        console.log('addInformationHandler...');
        console.log('informationData: ' + JSON.stringify(informationData));

        informationImageUpload(informationData).then(imageUrl => {
            const d = new FormData();
            d.append('image', informationData.image[0]);
            d.append('title', 'product');

            informationData.imageUrl = imageUrl;
            informationData.image = '';

            const requestOptions = {
                method: 'POST',
                body: JSON.stringify(informationData),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + auth.token,
                }
            };

            fetch(
                API_URL + "/api/information/add",
                requestOptions
            ).then((res) => {
                if (res.status == '201') {
                    Swal.fire({
                        title: '完成!',
                        text: '已加入',
                        icon: 'success',
                        confirmButtonText: '關閉'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/management/information");
                            navigate(0);
                        }
                    }
                    )

                } else {
                    Swal.fire({
                        title: '失敗!',
                        text: '資料錯誤',
                        icon: 'info',
                        confirmButtonText: '關閉'
                    })
                }

            });
        })

    }

    const removeInformationHandler = (id) => {
        setIsLoading(true);
        axios({
            method: "POST",
            url: API_URL + "/api/information/remove/" + id,
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + auth.token
            }

        }).then((res) => {
            setIsLoading(false);
            if (res.status == '201') {
                Swal.fire({
                    title: '完成!',
                    text: '已刪除',
                    icon: 'success',
                    confirmButtonText: '關閉'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/management/information");
                        navigate(0);
                    }
                }
                )

            } else {
                Swal.fire({
                    title: '失敗!',
                    text: '錯誤',
                    icon: 'info',
                    confirmButtonText: '關閉'
                })
            }

        })
        .catch((error) => {
            Swal.fire({
                title: '失敗!',
                text: '錯誤',
                icon: 'info',
                confirmButtonText: '關閉'
            })
            setIsLoading(false);
            console.log(error);
        });
    }

    return (
        <RootBox>
            <RootContainer className={classes.root}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="activity tab">
                        <Tab label="新增消息" {...a11yProps(0)} />
                        <Tab label="現有消息" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <NewInformationForm
                        addInformationHandler={addInformationHandler}
                        carparks={rows}
                        auth={auth}
                        informationImageUpload={informationImageUpload} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <InformationTable
                        addInformationHandler={addInformationHandler}
                        informationImageUpload={informationImageUpload}
                        auth={auth}
                        removeImageHandler={removeImageHandler}
                        removeInformationHandler={removeInformationHandler} />
                </TabPanel>
            </RootContainer>
        </RootBox>
    )
}

const PREFIX = "activityMgn";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const RootContainer = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    }
}));

const RootBox = styled(Box)(({ theme }) => ({

    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    paddingTop: '32px',
    color: theme.palette.text.primary,
}));

export default InformationManagementPage;