import { useState, useEffect, forwardRef, Fragment } from "react";
// React Router
import { useNavigate, useLocation, useParams } from "react-router-dom";
import MapIcon from '@mui/icons-material/Map';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CampaignIcon from '@mui/icons-material/Campaign';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { yellow } from '@mui/material/colors'
import { styled } from "@mui/material/styles";
import { API_URL } from '../environment';
import { toDateString, DateToMonthString, DateToDayString } from '../util/TimeUtil';
import Breadcrumb from "../components/layout/Breadcrumb";

const breadcrumbData = ["主頁", "活動", "過往活動"];

function createActivityData(id, title, content, images, createdDate, lastModifiedDate) {
    return { id, title, content, images, createdDate, lastModifiedDate };
}

function replaceNbsps(str) {
    var re = new RegExp(String.fromCharCode(160), "g");
    return (str == null) ? "" : str.replace("&nbsp;", " ").replace("&nbsp;", " ");
  }

function ActivityHistoryPage(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [activities, setActivities] = useState([]);
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [yearList, setYearList] = useState([]);
    const navigate = useNavigate();
    let data;

    const toContentPage = (id) => {
        navigate('/#/activity/' + id);
        //navigate(0); // force to reload page
    }

    const handleYearChange = (event) => {
        setYear(event.target.value);
        let year = event.target.value;
        setIsLoading(true);
        getActivities([API_URL + `/api/activity?year=` + year])
    };

    const handleMonthChange = (event) => {
        if (year !== '') {
            setMonth(event.target.value);
            let month = event.target.value;
            setIsLoading(true);
            getActivities([API_URL + `/api/activity?year=` + year + `&month=` + month]);
        }
        
    };
    
    const getActivities = async (urls) => {

        [data] = await Promise.all(
            urls.map((url) => fetch(url)
                .then((res) => { return res.json(); })
                .catch((error) => {
                    setIsLoading(false);
                    setActivities([]);
                    console.log(error);
                })
            )
        );
        setIsLoading(false);

        var activityData = [];

        for (var a of data) {
            let id = a['activityId'];
            let title = a['title'];
            let content = replaceNbsps(a['content']);
            let createdDate = a['createdDate'];
            let lastModifiedDate = a['lastModifiedDate'];

            let images = [];
            for (var i of a['activityImages']) {
                images.push(i['imageUrl']);
            }
            activityData.push(createActivityData(id, title, content, images, createdDate, lastModifiedDate));
        }
        setActivities(activityData);
    }

    useEffect(() => {
        try {
            const urls = [API_URL + `/api/activity`]
            getActivities(urls);

        } catch (error) {
            console.log(error);
        }

    }, []);

    useEffect(() => {
        var y = 2023;
        var yList = [];
        while (new Date().getFullYear() >= y) {
            yList.push(y);
            y++;
        }
        setYearList(yList);
    }, []);


    if (isLoading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <RootBox>
            <Root className={classes.root}>
                <Box sx={{ mt: {xs: 8.25, md: 12.5} }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Box sx={{ my: 4 }}>
                    <Typography variant="h4">過往活動</Typography>
                </Box>
                <Box>{/*sx={{ backgroundColor: "#f1f1f1"}}*/}
                    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }} sx={{ my: 2 }}>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="select-year-label">年份</InputLabel>
                                <Select
                                    labelId="select-year-label"
                                    id="select-year"
                                    value={year}
                                    label="year"
                                    onChange={handleYearChange}
                                >
                                    {
                                        yearList.map((item, i) => {
                                            return (
                                                <MenuItem key={item} value={item}>{item}年</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="select-month-label">月份</InputLabel>
                                <Select
                                    labelId="select-month-label"
                                    id="select-month"
                                    value={month}
                                    label="month"
                                    onChange={handleMonthChange}
                                >
                                    <MenuItem value={"all"}>所有</MenuItem>
                                    <MenuItem value={1}>1月</MenuItem>
                                    <MenuItem value={2}>2月</MenuItem>
                                    <MenuItem value={3}>3月</MenuItem>
                                    <MenuItem value={4}>4月</MenuItem>
                                    <MenuItem value={5}>5月</MenuItem>
                                    <MenuItem value={6}>6月</MenuItem>
                                    <MenuItem value={7}>7月</MenuItem>
                                    <MenuItem value={8}>8月</MenuItem>
                                    <MenuItem value={9}>9月</MenuItem>
                                    <MenuItem value={10}>10月</MenuItem>
                                    <MenuItem value={11}>11月</MenuItem>
                                    <MenuItem value={12}>12月</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <CustomDivider className={'cardContent'} />
                    <ActivityItem activities={activities} />
                    <CustomDivider className={'cardContent'} />
                </Box>
            </Root>
        </RootBox>
    )
}

const ActivityItem = ({activities}) => {
    return (
        <>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid container item sx={{ mt: 2 }}>
                        <Grid item xs={12} md={2}>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1">標題</Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Typography variant="subtitle1">內容</Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <CustomDivider className={'titleAndContent'} />
                    </Grid>
                    {
                        activities.map((item, i) => {
                            return (
                                <ContentMDGrid
                                    key={item.id}
                                    id={item.id}
                                    title={item.title}
                                    createdDate={item.createdDate}
                                    images={item.images}
                                    content={(item.content == null) ? "" : item.content.replace(/(<([^>]+)>)/ig, '')}
                                />
                            )
                        })
                    }
                </Grid>
            </Box>
            <Box justifyContent="flex-end" sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
                <Grid container>
                    {
                        activities.map((item, i) => {
                            return (
                                <ContentXSGrid
                                    key={item.id}
                                    id={item.id}
                                    title={item.title}
                                    createdDate={item.createdDate}
                                    images={item.images}
                                    content={(item.content == null) ? "" : item.content.replace(/(<([^>]+)>)/ig, '')}
                                />
                            )
                        })
                    }
                </Grid>
            </Box>
        </>
    )
}

function ContentGrid(props) {
    if (props.contentList.length > 0) {
        return (
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                {props.contentList.map((item) => (
                    <Grid item xs={12} sm={2} md={3} key={item.title}>
                        <Card sx={{ maxWidth: 345 }}>
                            <Link href="" onClick={(e) => { e.preventDefault(); props.toContentPage(item.redirectUrl) }} color="inherit">
                                <CardMedia
                                    component="img"
                                    alt={item.name}
                                    height="140"
                                    image={item.image}
                                    onClick={(e) => { e.preventDefault(); props.toContentPage(item.redirectUrl) }}
                                />
                            </Link>
                            <CustomDivider className={'cardContent'} />
                            <CardContent sx={{ height: 150 }}>
                                <Link href="" onClick={(e) => { e.preventDefault(); props.toContentPage(item.redirectUrl) }} underline="hover" color="#985b10">
                                    <Typography align="left" gutterBottom variant="subtitle1" component="div">
                                        {item.category === "promotion" ? "泊車優惠" : (item.category === "parkbin" ? "十八區玩樂" : "")}
                                    </Typography>
                                </Link>

                                <Link href="" onClick={(e) => { e.preventDefault(); props.toContentPage(item.redirectUrl) }} underline="hover" color="inherit">
                                    <Typography
                                        sx={{
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                        }}
                                        align="left" variant="h6" component="div">
                                        {item.title}
                                    </Typography>
                                </Link>
                                <Typography variant="body2" color="text.secondary">
                                    {item.createdDate}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        )
    } else {
        return (
            <>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={12} sm={2} md={3}>
                        <Typography variant="h4"><CampaignIcon fontSize="large" />過往活動</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2} md={3}>

                    </Grid>
                </Grid>
                <Box>
                    <Typography variant="h6" color="text.secondary">
                        未有相關資訊
                    </Typography>
                </Box>
            </>
        )
    }
}

const ContentMDGrid = ({ id, title, createdDate, images, content }) => {
    return (
        <>
            <Grid item xs={12} sm={2} md={2}>
                <Grid item xs={12} md={4}>
                    <Paper square className="ms-auto" style={{ backgroundColor: '#f1f1f1', padding: '1px 1px 1px 1px' }}>
                        <Typography variant="h6" color="text.primary" style={{ textAlign: 'center' }}>
                            {DateToMonthString(new Date(createdDate))}月
                        </Typography>
                        <Divider />
                        <Typography variant="subtitle1" color="text.primary" style={{ textAlign: 'center' }}>
                            {DateToDayString(new Date(createdDate))}
                        </Typography>
                    </Paper>
                </Grid>

            </Grid>
            <Grid item xs={12} sm={2} md={3}>
                <Typography variant="subtitle1" color="text.primary">{title}</Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={7}>
                <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                    }}>
                    {content}
                </Typography>
                <Link underline="hover" key="1" color="inherit" href={"/#/activity/" + id}>
                    <Typography variant="body1">詳情 <NavigateNextIcon /></Typography>
                </Link>
                <Grid container>
                    {/* <Stack direction="row"> */}
                    {
                        images.map((imageUrl, i) => {
                            if (i < 3) {
                                return (
                                    <Grid key={i} item md={4}>
                                        <Box sx={{ p: 1 }}>
                                            <img
                                                width="100%"
                                                height="175em"
                                                src={imageUrl}
                                                alt={imageUrl}
                                                style={{ objectFit: "cover" }}
                                            />
                                        </Box>
                                    </Grid>
                                )
                            }
                        })
                    }
                    {/* </Stack> */}
                </Grid>
            </Grid>
            <Grid item md={12}>
                <CustomDivider className={'titleAndContent'} />
            </Grid>
        </>
    )
}

const ContentXSGrid = ({ id, title, createdDate, images, content }) => {
    return (
        <>
            <Grid item xs={12} sm={12} md={3} sx={{ mt: 2 }}>
                <Card sx={{ maxHeight: { xs: 400, sm: '20rem', md: '20rem' } }}>
                    <Link href="" onClick={(e) => { e.preventDefault(); }} color="inherit">
                        <CardMedia
                            component="img"
                            alt={"#"}
                            width="30rem"
                            height={200}
                            image={images.length > 0 ? images[0] : "img/under-construction.jpg"}
                            onClick={(e) => { e.preventDefault(); }}
                        />
                    </Link>
                    <CustomDivider className={'cardContent'} />
                    <CardContent sx={{ height: 150 }}>
                        {/* <Link href="" onClick={(e) => { e.preventDefault(); }} underline="hover" color="#985b10">
                            <Typography align="left" gutterBottom variant="subtitle1" component="div">
                                分類標題
                            </Typography>
                        </Link> */}
                        <Link href={"/#/activity/" + id} underline="hover" color="inherit">
                            <Typography
                                sx={{
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                }}
                                align="left" variant="h5" component="div">
                                {title}
                            </Typography>
                        </Link>
                        <Typography variant="body1" color="text.secondary">
                            {toDateString(new Date(createdDate))}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.categoryResult`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.main,
        borderBottomWidth: 6,
        marginBottom: 20
    },
    [`&.cardContent`]: {
        background:
            theme.palette.mode === "dark"
                ? '#c9bea8'
                : '#c9bea8',
        borderBottomWidth: 5
    },
    [`&.titleAndContent`]: {
        background:
            theme.palette.mode === "dark"
                ? '#c9bea8'
                : '#c9bea8',
        borderBottomWidth: 2
    },

}));

const PREFIX = "catgoryResult";

const classes = {
    root: `${PREFIX}-root`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        height: '100%',
        backgroundColor: theme.palette.mode === 'dark'
            ? '#1A2027'
            : '#fff',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '90vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : '#FFFFFF',
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

export default ActivityHistoryPage;