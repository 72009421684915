import React, { useState, useEffect, forwardRef, Fragment, useRef } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from "@mui/material/styles";
import "../../index.css";
import { toDateString } from '../../util/TimeUtil';
// import { Key } from '../../imgurKey';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import ReactPlayer from 'react-player/file';
import { API_URL } from '../../environment';
import { ImageUpload } from '../../util/ImageUpload';
import { ImageRemove } from '../../util/ImageRemove';

// React Router
import { useNavigate } from "react-router-dom";

// Components
import { AuthProvider, useAuthState } from "../../store/app-context";


function HomeManagementPage() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    // const [banners, setBanners] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [status, setStatus] = useState();
    const [images, setImages] = useState([]);
    const imageInputRef = useRef();
    const [removeImage, setRemoveImage] = useState([]);

    // For Video
    const [video, setVideo] = useState([]);
    const videoInputRef = useRef();
    const [removeVideo, setRemoveVideo] = useState([]);

    const auth = useAuthState();

    useEffect(() => {
        const urls = [
            API_URL + `/api/file/get-banners`
        ]
        try {
            let searchingResult;
            const getBanners = async () => {
                [searchingResult] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setImages(searchingResult);
            }
            getBanners();

        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        const urls = [
            API_URL + `/api/file/get-homepage-video`
        ]
        try {
            let searchingResult;
            const getVideo = async () => {
                [searchingResult] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                    )
                );
                setIsLoading(false);
                setVideo(searchingResult);
            }
            getVideo();

        } catch (error) {
            console.log(error);
        }
    }, []);

    if (isLoading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    // to FTP directory
    const bannerImageUpload = async (images) => {
        try {
            for (var image of images) {
                const file = image;
                const formData = new FormData();

                formData.append('file', file);
                formData.append('directory', 'public_html/img/banner/')
                const res = await ImageUpload(formData, auth);

                console.log('res: ' + res);

                if (!res) {
                    console.log('no images');
                    Swal.fire({
                        text: '圖片未能上載',
                        icon: 'error',
                        confirmButtonText: '關閉'
                    })
                    break;
                }
            }

            // Only trigger when all images uploaded
            Swal.fire({
                text: '圖片上載完成',
                icon: 'success',
                allowOutsideClick: false,
                confirmButtonText: '關閉'
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        navigate(0)
                    }
                })
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    // Remove from FTP
    const removeImageHandler = async (imageIds) => {
        const directory = 'public_html/img/banner/';

        try {
            for (var image of imageIds) {
                // Convert to filename only
                const filename = image.substr(image.lastIndexOf('/') + 1);
                const formData = new FormData();

                formData.append('filename', filename);
                formData.append('directory', directory)
                const res = await ImageRemove(formData, auth);

                if (res.status != '200') {
                    Swal.fire({
                        text: '圖片未能刪除',
                        icon: 'error',
                        confirmButtonText: '關閉'
                    })
                    break;
                }
            }

            // only trigger when images removed
            Swal.fire({
                text: '圖片已經刪除',
                icon: 'success',
                allowOutsideClick: false,
                confirmButtonText: '關閉'
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        navigate(0)
                    }
                })
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    // Replace Homepage Video
    const replaceHomepageVideo = async (newVideo) => {
        try {

            const file = newVideo;
            const formDataUpload = new FormData();

            
            const filename = video[0] != null ? video[0].substring(video[0].lastIndexOf('/') + 1) : "";
            const formDataRemove = new FormData();

            formDataUpload.append('file', file);
            formDataUpload.append('directory', 'public_html/video/')
            formDataRemove.append('filename', filename);
            formDataRemove.append('directory', 'public_html/video/')
            const resRemove = await ImageRemove(formDataRemove, auth); // TODO: ImageRemove() -> FileRemove()
            const resUpload = await ImageUpload(formDataUpload, auth); // TODO: ImageUpload() -> FileUpload()

            console.log('resRemove: ' + resRemove);
            console.log('resUpload: ' + resUpload);

            if (resRemove && resUpload) {
                Swal.fire({
                    text: '影片上載完成',
                    icon: 'success',
                    allowOutsideClick: false,
                    confirmButtonText: '關閉'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        navigate(0)
                    }
                })
            } else {
                Swal.fire({
                    text: '影片未能上載',
                    icon: 'error',
                    confirmButtonText: '關閉'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate(0)
                    }
                })
            }

            
        } catch (error) {
            setIsLoading(false)
            console.error(error);
            Swal.fire({
                text: '發生未知錯誤',
                icon: 'error',
                confirmButtonText: '關閉'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(0);
                }
            })
        }
    }

    const removePreviewImageHandler = (imageId) => {
        setRemoveImage(image => [...image, imageId]);
        const newImages = [];
        for (var img of images) {
            if (img !== imageId) {
                newImages.push(img);
            } else {

            }
        }
        setImages(newImages);
    }

    const submitHandler = (event) => {
        event.preventDefault();

        setIsLoading(true);
        let images = [];
        const fileCount = document.getElementsByClassName('input-image')[0].files.length;
        for (var i = 0; i < fileCount; i++) {
            const tmpImage = document.getElementsByClassName('input-image')[0].files[i];
            images.push(tmpImage);
        }

        if (images.length > 0) {
            bannerImageUpload(images);
        }
        if (removeImage.length > 0) {
            removeImageHandler(removeImage);
        }
    }

    const submitVideoHandler = (event) => {
        event.preventDefault();

        setIsLoading(true);
        const fileCount = document.getElementsByClassName('input-video')[0].files.length;
        const newVideo = document.getElementsByClassName('input-video')[0].files[0];

        console.log('video count: ' + fileCount);
        if (fileCount == 1) {
            replaceHomepageVideo(newVideo);
        }
        // if (images.length > 0) {
        //     bannerImageUpload(images);
        // }
        // if (removeImage.length > 0) {
        //     removeImageHandler(removeImage);
        // }
    }

    return (
        <RootBox>
            <RootContainer className={classes.root}>
                <Typography variant="h2">Welcome, {auth.userDetails}</Typography>
                <Box sx={{ mt: 1, mb: 5 }} >
                    <Typography variant="subtitle1">首頁 Banner</Typography>
                    <Typography color="red" variant="subtitle2">*請避免同時上載與刪除</Typography>
                    <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
                        {images.map((imageUrl, i) => (
                            <Box sx={{ position: 'relative' }} key={i}>
                                <Box sx={{ my: 1 }}>
                                    <img src={imageUrl} alt={imageUrl} style={{ maxWidth: '100px' }} />
                                </Box>
                                <Box sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    bgcolor: 'rgba(0, 0, 0, 0.54)',
                                    color: 'white',
                                    // padding: '1px',
                                }}>
                                    <Grid container justifyContent="flex-end">
                                        <CloseIcon onClick={() => { removePreviewImageHandler(imageUrl) }} />
                                    </Grid>
                                </Box>
                            </Box>
                        ))}
                    </Stack>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2">新相片</Typography>
                        <input type="file" className="input-image" id="image" ref={imageInputRef} multiple />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Button variant="contained" onClick={submitHandler}>確定更改</Button>
                    </Box>
                </Box>
                <Divider sx={{ borderBottomWidth: 2, background: 'black'}}/>
                <Box sx={{ mt: 1, mb: 5 }} >
                    <Typography variant="subtitle1">首頁影片</Typography>
                    <Typography color="red" variant="subtitle2">*請避免同時上載與刪除</Typography>
                    {video.length > 0 ?
                        <ReactPlayer
                            url={video[0]}
                            controls={true}
                            width="100%"
                            height="20rem"
                        /> :
                        <Box>No Video</Box>
                    }
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2">新影片</Typography>
                        <input type="file" className="input-video" id="video" ref={videoInputRef} />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Button variant="contained" onClick={submitVideoHandler}>確定更改</Button>
                    </Box>
                </Box>
            </RootContainer>
        </RootBox>
    )
}

const PREFIX = "activityMgn";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const RootContainer = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    }
}));

const RootBox = styled(Box)(({ theme }) => ({

    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    paddingTop: '32px',
    color: theme.palette.text.primary,
}));

export default HomeManagementPage;