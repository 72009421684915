import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function handleClick(event) {
    event.preventDefault();
}

export default function Breadcrumb(props) {
    const { breadcrumbData } = props;
    let breadcrumbs = []

    if (breadcrumbData.length === 2) {
        breadcrumbs = [
            <Link underline="hover" key="1" color="inherit" href="/">
                {breadcrumbData[0]}
            </Link>,
            <Typography key="3" color="text.primary">
                {breadcrumbData[1]}
            </Typography>,
        ]
    } else if (breadcrumbData.length === 3) {
        breadcrumbs = [
            <Link underline="hover" key="1" color="inherit" href="/" >
                {breadcrumbData[0]}
            </Link>,
            // <Link
            //     underline="hover"
            //     key="2"
            //     color="inherit"
            //     href="/material-ui/getting-started/installation/"
            //     onClick={handleClick}
            // >
            <Typography key="3" color="text.primary">
                {breadcrumbData[1]}
            </Typography>,
            // </Link>,
            <Typography key="3" color="text.primary">
                {breadcrumbData[2]}
            </Typography>,
        ];
    }



    return (
        <Stack spacing={2}>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
}