import { useState, useEffect, forwardRef, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Typography from "../../Typography";
import { useAuthState } from "../../../store/app-context";

// Hooks
import { useRef } from "react";

function NewPressReleaseForm(props) {
    const [status, setStatus] = useState();
    const [images, setImages] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const titleInputRef = useRef();
    const imageInputRef = useRef();
    const documentInputRef = useRef();

    function submitHandler(event) {
        event.preventDefault();
        setBtnLoading(true);

        const title = titleInputRef.current.value;
        const createdDate = new Date();
        const lastModifiedDate = new Date();
        const image = document.getElementsByClassName('input-image')[0].files[0];
        const attachment = document.getElementsByClassName('input-document')[0].files[0];

        const pressReleaseDocument = {
            title: title,
            // attachment: attachment,
            createdDate: createdDate,
            lastModifiedDate: lastModifiedDate
        };

        console.log(pressReleaseDocument);

        props.addPressReleaseHandler(pressReleaseDocument, image, attachment);
        setBtnLoading(false);
    }

    const handleChange = (event) => {
        setStatus(event.target.value);
    };


    return (
        <Box sx={{ mt: 1, mb: 5 }} >
                <Box sx={{ my: 1 }}>
                    <Typography sx={{ typography: 'subtitle1' }}>標題</Typography>
                    <TextField required id="title" inputRef={titleInputRef} size="small" sx={{ width: '100%' }} />
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography sx={{ typography: 'subtitle1' }}>封面圖片</Typography>
                    <input type="file" className="input-image" required id="image" ref={imageInputRef} />
                </Box>
                <Box sx={{ my: 1 }}>
                    <Typography sx={{ typography: 'subtitle1' }}>文件</Typography>
                    <input type="file" className="input-document" required id="document" ref={documentInputRef} />
                </Box>
                <Box sx={{ my: 1 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <LoadingButton 
                                variant="contained" 
                                color="warning"
                                loading={btnLoading}
                                loadingPosition="start"  
                                startIcon={<SaveIcon/>}
                                onClick={submitHandler}
                            >
                                <span>加入</span>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
                </Box>
        </Box>
    )
}

export default NewPressReleaseForm;