import React, { useState, useEffect, forwardRef, Fragment, useRef } from "react";
import {
    Container, Slide, Box, Divider, Typography, Grid, Paper
    , Card, TextField, Button, Backdrop, CircularProgress
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "../index.css";
import Breadcrumb from "../components/layout/Breadcrumb";
import Swal from 'sweetalert2';
import { API_URL } from '../environment';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import FadeInSection from "../util/FadeInSection";

// React Router
import { useNavigate } from "react-router-dom";

const breadcrumbData = ["主頁", "資訊中心", "刊物"];

function createData(prId, title, image, documentUrl, createdDate, lastModifiedDate) {
    return { prId, title, image, documentUrl, createdDate, lastModifiedDate };
}

let rows = [];

function PressReleasePage(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [loadPrs, setPrs] = useState([]);

    // click to download file
    const onDownloadButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('/file/press-releases/2023/03/test-pdf.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'test-pdf.pdf';
                alink.click();
            })
        })
    }

    useEffect(() => {
        rows = [];
        const urls = [
            API_URL + `/api/press-release`
        ]
        try {
            var prs;
            let tmpArr = [];
            let count = 0;
            let total = 0;

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            const getPrs = async () => {
                [prs] = await Promise.all(
                    urls.map((url) => fetch(url, requestOptions)
                        .then((res) => { return res.json(); })
                    )
                );
                // Reorder prs by createdDate
                prs = prs.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

                setIsLoading(false);
                // setPrs(prs);

                for (var pr of prs) {

                    let prId = pr['prId'];
                    let title = pr['title'];
                    let image = pr['image']
                    let documentUrl = pr['documentUrl'];
                    let createdDate = pr['createdDate']
                    let lastModifiedDate = pr['lastModifiedDate'];

                    tmpArr.push(createData(prId, title, image, documentUrl, createdDate, lastModifiedDate));
                    count++;
                    total++;
                    // 2D array, each sub array contain max 4 items
                    if (count == 4) {
                        rows.push(tmpArr);
                        tmpArr = [];
                        count = 0;
                    }

                    // for last row
                    if(total == prs.length) {
                        rows.push(tmpArr);
                    }

                }
                setPrs(rows);
            }
            getPrs();

        } catch (error) {
            console.log(error);
        }

    }, []);

    return (
        <RootBox sx={{ minHeight: 'calc(100vh - 210px)' }}>
            <Root className={classes.root}>
                <Box sx={{ mt: { xs: 8.25, md: 12.5 } }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Box sx={{ my: 4 }}>
                    <Typography variant="h4">刊物</Typography>
                </Box>
                {
                    loadPrs.map((item, i) => {
                        return (
                            <Grid container spacing={2} sx={{ py: { md: 4 } }} key={i}>
                                {
                                    item.map((pr, j) => {
                                        return (
                                            <Grid item xs={12} sm={12} md={3} key={j}>
                                                <FadeInSection ordering={j == 0 ? "" : j + 1}>
                                                    <Link href={pr.documentUrl} target="_blank" underline="hover" color="inherit">
                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            <Box
                                                                component="img"
                                                                sx={{
                                                                    // height: 233,
                                                                    // width: 350,
                                                                    maxHeight: { xs: 233, md: 400 },
                                                                    maxWidth: { xs: 350, md: 250 },
                                                                }}
                                                                alt={pr.image}
                                                                src={pr.image}
                                                            />
                                                        </Box>

                                                        <Box>
                                                            <Typography variant="h6" style={{ textAlign: 'center' }}>{pr.title}</Typography>
                                                        </Box>
                                                    </Link>
                                                </FadeInSection>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        )
                    })
                }
            </Root>
        </RootBox>
    )
}

const PREFIX = "introdution";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.position ? props.position : "center"}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const SubTitle = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.subtitle ? props.subtitle : "center"}
                >
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.about`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : theme.palette.primary.main,
        borderBottomWidth: 3,
        width: "100%"
        // marginBottom: 10
    },
    [`&.companyBusiness`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : 'red',
        borderBottomWidth: 2,
        width: "5%"
        // marginBottom: 10
    },

}));

export default PressReleasePage;