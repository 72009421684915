import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Markdown from '../components/Markdown';
import Typography from '../components/Typography';
import withRoot from '../withRoot';
import privacy from '../views/privacy.md';

function PrivacyPage(props) {
  const [privacy, setPrivacy] = useState('');

    useEffect(() => {
        import(`../views/privacy.md`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setPrivacy(res))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    });

  return (
    <React.Fragment>
      {/* <AppAppBar /> */}
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Privacy
          </Typography>
          <Markdown>{privacy}</Markdown>
        </Box>
      </Container>
      {/* <AppFooter /> */}
    </React.Fragment>
  );
}

export default withRoot(PrivacyPage);
// export default PrivacyPage;
