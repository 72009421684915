import { useState, useEffect, forwardRef, Fragment } from "react";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Typography from "@mui/material/Typography";
import { useAuthState } from "../../../store/app-context";

// Hooks
import { useRef } from "react";

function NewCommitteeMemberForm({addMemberHandler, committees}) {
    const [memberName, setMemberName] = useState();
    const [btnLoading, setBtnLoading] = useState(false);
    const [committeeId, setCommitteeId] = useState();
    const memberNameInputRef = useRef();
    const committeeIdInputRef = useRef();
    const auth = useAuthState();

    function submitHandler(event) {
        event.preventDefault();
        setBtnLoading(true);

        const memberName = memberNameInputRef.current.value;
        const committeeId = committeeIdInputRef.current.value;


        
        const memberData = {
            name: memberName,
            committeeId: committeeId,
        };

        console.log(memberData);

        addMemberHandler(memberData);
        setBtnLoading(false);
    }

    return (
        <Box sx={{ mt: 1, mb: 5 }} >
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Box sx={{ my: 1 }}>
                            <label htmlFor="title">委員會</label>
                            <FormControl fullWidth size="small">
                                <Select
                                    labelId="select-year-label"
                                    id="select-committee-id"
                                    label="committeeId"
                                    inputRef={committeeIdInputRef}
                                >
                                    {
                                        committees.map((committee, i) => {
                                            return (
                                                <MenuItem key={committee.id} value={committee.id}>{committee.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <Box sx={{ my: 1 }}>
                            <label htmlFor="title">委員名稱</label>
                            <TextField required id="title" inputRef={memberNameInputRef} size="small" sx={{ width: '100%' }} />
                        </Box>
                    </Grid>

                </Grid>
               
                <Box sx={{ my: 1 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <LoadingButton 
                                variant="contained" 
                                color="warning"
                                loading={btnLoading}
                                loadingPosition="start"  
                                startIcon={<SaveIcon/>}
                                onClick={submitHandler}
                            >
                                <span>加入</span>
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
                </Box>
        </Box>
    )
}

export default NewCommitteeMemberForm;