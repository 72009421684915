import React, { useState, useEffect, forwardRef } from "react";
import {
    Container, Slide, Box, Divider, Typography, Grid, Paper, List, ListItem, ListItemText
} from "@mui/material";
import { grey } from '@mui/material/colors';
import { styled } from "@mui/material/styles";
import "../index.css";
import Breadcrumb from "../components/layout/Breadcrumb";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { API_URL } from '../environment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// React Router
import { useNavigate } from "react-router-dom";

const breadcrumbData = ["主頁", "關於我們", "轄下委員會"];

function CommitteePage(props) {
    const [expanded, setExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [committees, setCommittees] = useState([]);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    let data;

    // change title
    // useEffect(() => {
    //     document.title = '轄下委員會';
    // });
    let committeeTmp = [
        {
            "committeeId": 9,
            "members": [
                {
                    "memberId": 1,
                    "committeeId": 13,
                    "name": "黃逸郎先生 (主席)"
                },
                {
                    "memberId": 1,
                    "committeeId": 13,
                    "name": "黃家奎先生 (副主席)"
                }
            ],
            "name": "公務政策委員會"
        },
        {
            "committeeId": 13,
            "members": [
                {
                    "memberId": 1,
                    "committeeId": 13,
                    "name": "姜銘基先生 (主席)"
                },
                {
                    "memberId": 1,
                    "committeeId": 13,
                    "name": "黃逸郎先生 (副主席)"
                }
            ],
            "name": "建造業議會委員會"
        },
        {
            "committeeId": 13,
            "members": [
                {
                    "memberId": 1,
                    "committeeId": 13,
                    "name": "蕭貝丰先生 (主席)"
                },
                {
                    "memberId": 1,
                    "committeeId": 13,
                    "name": "黃逸郎先生 (副主席)"
                }
            ],
            "name": "內地事務委員會"
        },
        {
            "committeeId": 13,
            "members": [
                {
                    "memberId": 1,
                    "committeeId": 13,
                    "name": "周海雄先生 (主席)"
                },
                {
                    "memberId": 1,
                    "committeeId": 13,
                    "name": "陳國彬先生 (副主席)"
                }
            ],
            "name": "供應商事務委員會"
        },
        {
            "committeeId": 13,
            "members": [
                {
                    "memberId": 1,
                    "committeeId": 13,
                    "name": "陸偉林先生 (主席)"
                },
                {
                    "memberId": 1,
                    "committeeId": 13,
                    "name": "陳國彬先生 (副主席)"
                }
            ],
            "name": "從業員發展委員會"
        }
        
    ]

    useEffect(() => {
        document.title = '轄下委員會';

        try {
            const urls = [API_URL + `/api/committee`];

            const getCommittees = async () => {

                [data] = await Promise.all(
                    urls.map((url) => fetch(url)
                        .then((res) => { return res.json(); })
                        .catch((error) => {
                            setIsLoading(false);
                            setCommittees([]);
                            console.log(error);
                        })
                    )
                );
                setIsLoading(false);

                var committeeData = [];

                for (var c of committeeTmp) {
                    let id = c['committeeId'];
                    let name = c['name'];

                    let members = [];
                    for (var i of c['members']) {
                        members.push(i['name']);
                    }
                    committeeData.push({ id, name, members });
                }
                setCommittees(committeeData);
            }

            getCommittees();

        } catch (error) {
            console.log(error);
        }

    }, []);

    if (isLoading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <RootBox sx={{ minHeight: 'calc(100vh - 210px)' }}>
            <Root className={classes.root}>
                <Box sx={{ mt: { xs: 8.25, md: 12.5 } }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                <Box>
                    <Box sx={{ my: 4 }}>
                        <Typography variant="h4">{"轄下委員會"}</Typography>
                    </Box>
                    {committees.length > 0 ?
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 12 }}>
                            {
                                committees.map((item, i) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={6}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            key={item.id}
                                        >
                                            <Box sx={{ minWidth: '100%' }}>
                                                <Committee name={item.name} members={item.members} />
                                            </Box>
                                        </Grid>
                                    )
                                })

                            }
                        </Grid>
                        :
                        <>
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="h5">
                                    建設中，敬請期待
                                </Typography>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <img src="/img/under-construction.jpg" alt="under-construction" style={{ maxWidth: '300px' }} />
                            </Box>
                        </>
                    }

                </Box>
            </Root>
        </RootBox>
    )

}

const Committee = ({ name, members }) => {
    return (
        <Card variant="outlined" sx={{ minHeight: '12.5rem' }}>
            <CardContent>
                <Typography varient="h4" color="text.primary" gutterBottom>
                    {name}
                </Typography>
                <List dense={true}>
                    {

                        members.map((member, i) => {
                            return (
                                <div>
                                <ListItem key={i}>
                                        {/* 🙍‍♂️<ListItemText
                                            sx={{ ml: 2}} primary={member}
                                        /> */}
                                        🙍‍♂️<ListItemText
                                            sx={{ ml: 2}} primary={member}
                                        />
                                </ListItem>
                                </div>
                            )
                        })
                    }
                </List>
            </CardContent>
        </Card>
    )
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PREFIX = "contact-us";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.position ? props.position : "center"}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.about`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 3,
        width: "100%"
        // marginBottom: 10
    },

}));

export default CommitteePage;