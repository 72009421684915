import { API_URL } from '../environment';
import Swal from 'sweetalert2';

// to FTP directory
export async function ImageRemove(formData, auth) {
    try {
        let response;
        await fetch(
            API_URL + "/api/file/remove-file",
            {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': 'Bearer ' + auth.token,
                },
            }
        )
        .then((result) => {
            response = result;
        })
        return response;
    } catch (error) {
        console.error(error);
        return false;
    }

}
