import React, { useState, useEffect, forwardRef, Fragment, useRef } from "react";
import {
    Container, Slide, Box, Divider, Typography, Grid, Paper
    , Card, TextField, Button, Backdrop, CircularProgress
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "../index.css";
import Breadcrumb from "../components/layout/Breadcrumb";
import Swal from 'sweetalert2';
import { API_URL } from '../environment';
import Stack from '@mui/material/Stack';

// React Router
import { useNavigate } from "react-router-dom";

const breadcrumbData = ["主頁", "聯絡我們", "網上表格 - 查詢"];

const fetchTimeout = (url, ms, { signal, ...options } = {}) => {
    const controller = new AbortController();
    const promise = fetch(url, { signal: controller.signal, ...options });
    if (signal) signal.addEventListener("abort", () => controller.abort());
    const timeout = setTimeout(() => controller.abort(), ms);
    return promise.finally(() => clearTimeout(timeout));
};

function EnquiryPage(props) {
    const navigate = useNavigate();
    const guestNameInputRef = useRef();
    const guestEmailInputRef = useRef();
    const contentInputRef = useRef();
    const [isLoading, setIsLoading] = useState(false);

    // change title
    useEffect(() => {
        document.title = '聯絡我們';
    });

    function addEnquiryHandler(enquiryData) {
        setIsLoading(true);

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(enquiryData),
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + auth.token,
            }
        };

        const controller = new AbortController()

        fetch(
            API_URL + "/api/enquiry/create",
            requestOptions,
            { signal: controller.signal }
        ).then((res) => {
            setIsLoading(false);
            if (res.status == '201') {
                Swal.fire({
                    title: '完成!',
                    text: '已提交，你將會收到一封由系統自動發出的電郵',
                    icon: 'success',
                    confirmButtonText: '關閉',
                    customClass: {
                        container: 'swal2-container-custom',
                    }
                })
            } else {
                Swal.fire({
                    title: '失敗!',
                    text: '發生未知的錯誤',
                    icon: 'info',
                    confirmButtonText: '關閉'
                })
            }
        }).catch(error => {
            if (error.name === "AbortError") {
                // fetch aborted either due to timeout or due to user clicking the cancel button
                console.log('timeout');
            } else {
                // network error or json parsing error
            }
        });
    }

    const resetHandler = (event) => {
        event.preventDefault();

        guestNameInputRef.current.value = "";
        guestEmailInputRef.current.value = "";
        contentInputRef.current.value = "";
    }

    function submitHandler(event) {
        event.preventDefault();

        const guestName = guestNameInputRef.current.value;
        const guestEmail = guestEmailInputRef.current.value;
        const content = contentInputRef.current.value;
        const status = "0";
        const createdDate = new Date();
        const lastModifiedDate = new Date();

        const enquiryData = {
            guestName: guestName,
            guestEmail: guestEmail,
            enquiryContent: content,
            status: status,
            enquiryDate: createdDate
        };

        if (formValidation(guestName, guestEmail, content)) {
            addEnquiryHandler(enquiryData);
        }
    }

    const validateEmail = (emailAddress) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
            return true;
        }
        return false;
    }

    const formValidation = (guestName, guestEmail, content) => {
        if (guestName === '' || guestEmail === '' ||
            content === '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: '請填寫所有必要資訊！',
            })
            return false;
        }
        if (!validateEmail(guestEmail)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: '請輸入正確電郵地址！',
            })
            return false;
        }

        return true;
    }

    if (isLoading) {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <RootBox sx={{ minHeight: 'calc(100vh - 210px)' }}>
            <Root className={classes.root}>
                <Box sx={{ mt: { xs: 8.25, md: 12.5 } }}>
                    <Breadcrumb breadcrumbData={breadcrumbData} />
                </Box>
                    <Grid item xs={12} sm={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Title title={"網上表格 - 查詢"} position={"left"} />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ pb: { xs: 2, md: 5 } }}
                        >
                            <CustomDivider className={'about'} />
                        </Box>
                        <Typography variant="subtitle2">*必須填寫</Typography>
                        <Box sx={{ mt: 1, mb: 5 }} >
                            <TextField required id="name" label="名字" variant="outlined" margin="dense"
                                type="text"
                                inputRef={guestNameInputRef}
                                sx={{ width: '100%' }}
                            />
                            <TextField required id="email" label="電郵" variant="outlined" margin="dense"
                                type="text"
                                inputRef={guestEmailInputRef}
                                sx={{ width: '100%' }}
                            />
                            <TextField required id="content" label="內容 (不超過1,000個英文字母或500個中文字)" variant="outlined" margin="dense"
                                inputRef={contentInputRef}
                                sx={{ width: '100%' }}
                                multiline
                                maxRows={5}
                                minRows={5}
                            />
                            <Stack direction="row" spacing={1}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={submitHandler}
                                >
                                    遞交
                                </Button>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={resetHandler}
                                >
                                    重設
                                </Button>
                            </Stack>
                        </Box>
                </Grid>
            </Root>
        </RootBox>
    )
}

const PREFIX = "enquiry";

const classes = {
    root: `${PREFIX}-root`,
    loading: `${PREFIX}-loading`,
};

const Root = styled(Container)(({ theme }) => ({
    [`&.${classes.root}`]: {
        // width: '100%',
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        height: '100%',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
    [`&.${classes.loading}`]: {
        height: '100vh',
        background:
            theme.palette.mode === "dark"
                ? theme.palette.background.primary
                : "#fff",
        color:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
        divider:
            theme.palette.mode === "dark"
                ? "#fff"
                : theme.palette.text.primary,
    },
}));

const RootBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark'
        ? '#1A2027'
        : `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('img/bg.png')`,
    variant: 'outlined',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

const Title = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.position ? props.position : "center"}
                >
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const SubTitle = (props) => {
    return (
        <Box sx={{ my: 2, flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
            <Grid container spacing={{ xs: 1, md: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={12} sm={12} md={12}
                    container
                    spacing={0}
                    direction="row"
                    justifyContent={props.subtitle ? props.subtitle : "center"}
                >
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const CustomDivider = styled(Divider)(({ theme }) => ({
    [`&.about`]: {
        background:
            theme.palette.mode === "dark"
                ? theme.palette.primary.main
                : '#7A5D26',
        borderBottomWidth: 3,
        width: "100%"
        // marginBottom: 10
    },

}));

export default EnquiryPage;